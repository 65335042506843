import {
    DatagridConfigurable,
    DatagridProps,
    DateField,
    NumberField,
    ReferenceField,
    TextField,
} from 'react-admin';
import LinkInNewWindowField from '../common/linkInNewWindowField';
import NicheField from './fields/NicheField';
import LikeField from './fields/LikeField';
import PriceField from './fields/PriceField';
import AhrefsLogo from '../../layout/images/ahrefs-logo.png';
import MozLogo from '../../layout/images/moz-logo.png';
import SemrushLogo from '../../layout/images/semrush-logo.png';

const LIKE_WIDTH = 70;
const PRICE_WIDTH = 160;

export const MarketplaceDatagrid = (props: DatagridProps) => (
    <DatagridConfigurable
        bulkActionButtons={false}
        rowClick="edit"
        {...props}
        omit={[]}
        sx={{
            overflowX: 'auto',
            width: props.width,

            '& [class*="column-"]': {
                textAlign: 'center',
                width: '100%',
            },
            '& [class*="like"]': {
                textAlign: 'center',
                minWidth: `${LIKE_WIDTH}px`,
                maxWidth: `${LIKE_WIDTH}px`,
                position: 'sticky',
                left: 0,
                backgroundColor: '#fafafa',
                zIndex: 3,
            },
            '& [class*="price"]': {
                textAlign: 'center',
                minWidth: `${PRICE_WIDTH}px`,
                maxWidth: `${PRICE_WIDTH}px`,
                position: 'sticky',
                left: `${LIKE_WIDTH}px`,
                backgroundColor: '#fafafa',
                zIndex: 3,
            },
            '& [class*="website_name"]': {
                textAlign: 'center',
                position: 'sticky',
                left: `${LIKE_WIDTH + PRICE_WIDTH}px`,
                backgroundColor: '#fafafa',
                zIndex: 3,
            },
            '& .RaDatagrid-headerCell': {
                lineBreak: '1',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                backgroundColor: '#f5f5f5f5',
            },
            '& .RaDatagrid-rowEven': {
                '& .column-price ': { backgroundColor: '#039be5' },
                '& .column-website_name': { backgroundColor: '#fff' },
                '& .column-like': { backgroundColor: '#fff' },
            },
            '& .RaDatagrid-rowOdd': {
                '& .column-price ': { backgroundColor: '#007dba ' },
            },

            // Display icons in the header for specific columns with before element
            '& .MuiTableSortLabel-root[data-field="Website.MOZDa"]:before, .MuiTableSortLabel-root[data-field="Website.MOZSpamScore"]:before':
                {
                    content: "''",
                    display: 'block',
                    background: `url(${MozLogo}) no-repeat`,
                    width: '33px',
                    height: ' 10px',
                    float: 'left',
                    margin: '0 6px 0 0',
                },
            '& .MuiTableSortLabel-root[data-field="Website.SemrushAS"]:before':
                {
                    content: "''",
                    display: 'block',
                    background: `url(${SemrushLogo}) no-repeat`,
                    width: '80px',
                    height: ' 10px',
                    float: 'left',
                    margin: '0 6px 0 0',
                },
            '& .MuiTableSortLabel-root[data-field="Website.AhrefsDR"]:before': {
                content: "''",
                display: 'block',
                background: `url(${AhrefsLogo}) no-repeat`,
                width: '40px',
                height: ' 10px',
                float: 'left',
                margin: '0 6px 0 0',
            },
        }}
    >
        <LikeField label="Like" source="like" />
        <PriceField label="Price" source="price" />
        <LinkInNewWindowField
            propertyName="website_name"
            label="Offer Name"
            source="website_name"
            showPropertyName={true}
        />
        <ReferenceField
            label="Language"
            source="LanguageId"
            reference="v1_new_languagesBase"
            link="show"
        >
            <TextField source="new_name" />
        </ReferenceField>
        <ReferenceField
            label="DR"
            source="website_id"
            reference="v1_Website"
            link="show"
            sortBy="Website.AhrefsDR"
        >
            <NumberField source="AhrefsDR" />
        </ReferenceField>
        <ReferenceField
            label="AS"
            source="website_id"
            reference="v1_Website"
            link="show"
            sortBy="Website.SemrushAS"
        >
            <NumberField source="SemrushAS" />
        </ReferenceField>
        <ReferenceField
            label="DA"
            source="website_id"
            reference="v1_Website"
            link="show"
            sortBy="Website.MOZDa"
        >
            <NumberField source="MOZDa" />
        </ReferenceField>
        <ReferenceField
            label="Spam Score"
            source="website_id"
            reference="v1_Website"
            link="show"
            sortBy="Website.MOZSpamScore"
        >
            <NumberField source="MOZSpamScore" />
        </ReferenceField>
        <ReferenceField
            label="Organic Traffic"
            source="website_id"
            reference="v1_Website"
            link="show"
            sortBy="Website.OrganicTraffic"
        >
            <NumberField source="OrganicTraffic" />
        </ReferenceField>
        <ReferenceField
            label="Reffering Domains"
            source="website_id"
            reference="v1_Website"
            link="show"
            sortBy="Website.ReferringDomains"
        >
            <NumberField source="ReferringDomains" />
        </ReferenceField>
        <ReferenceField
            label="Outgoing Domains"
            source="website_id"
            reference="v1_Website"
            link="show"
            sortBy="Website.OutgoingDomains"
        >
            <NumberField source="OutgoingDomains" />
        </ReferenceField>
        <ReferenceField
            label="SISTRIX Visibility Index"
            source="website_id"
            reference="v1_Website"
            link="show"
            sortBy="Website.Sichtbarkeitsindex"
        >
            <NumberField source="Sichtbarkeitsindex" />
        </ReferenceField>
        <ReferenceField
            label="Homepage Link"
            source="website_id"
            reference="v1_Website"
            link="show"
            sortBy="Website.IsHomepageLink"
        >
            <TextField source="IsHomepageLink" />
        </ReferenceField>
        <ReferenceField
            label="Native Link"
            source="website_id"
            reference="v1_Website"
            link="show"
            sortBy="Website.NativeLink"
        >
            <TextField source="NativeLink" />
        </ReferenceField>
        <ReferenceField
            label="Domain Age"
            source="website_id"
            reference="v1_Website"
            link="show"
            sortBy="Website.DomainAge"
        >
            <TextField source="DomainAge" />
        </ReferenceField>
        <DateField label="Upload Date" source="AddedOn" locales="en-GB" />
        <ReferenceField
            label="Domain Extension"
            source="DomainExtensionId"
            reference="v1_DomainExtensions"
            link="show"
            sortBy="DomainExtension.Name"
        >
            <TextField source="Name" />
        </ReferenceField>
        <ReferenceField
            label="Category"
            source="MarketPlaceCategoriesId"
            reference="v1_MarketPlaceCategories"
            link="show"
            sortBy="MarketPlaceCategory.CategoryName"
        >
            <TextField source="CategoryName" />
        </ReferenceField>
        <ReferenceField
            label="Niche"
            source="NicheId"
            reference="v1_MarketPlaceOfferNiche"
            link="show"
            sortable={false}
        >
            <NicheField />
        </ReferenceField>
    </DatagridConfigurable>
);
