import Box from '@mui/material/Box';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useGetList, useInput } from 'react-admin';
import { useFormContext } from 'react-hook-form';

export default function DomainExtensionFilter({ source, label }: any) {
    const { unregister } = useFormContext();
    const { data: extensions } = useGetList('v1_DomainExtensions', {
        pagination: { page: 1, perPage: 3000 },
    });

    const { field } = useInput({
        source: `${source}@_eq`,
        defaultValue: '',
    });

    const handleChange = (event: any) => {
        if (event.target.value === '') {
            unregister(`${source}@_eq`, { keepTouched: true });
        } else {
            field.onChange(event.target.value);
        }
    };

    return (
        <Box sx={{ width: 370 }}>
            <FormControl fullWidth>
                <InputLabel id={`${label}-filter`}>{label}</InputLabel>
                <Select
                    labelId={`${label}-filter`}
                    id={`${label}-filter-select`}
                    label={`${label}`}
                    value={field.value}
                    onChange={handleChange}
                >
                    <MenuItem value="">All</MenuItem>
                    {extensions?.map((extension: any) => (
                        <MenuItem key={extension.id} value={extension.id}>
                            {extension.Name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );
}
