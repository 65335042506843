import { CircularProgress } from "@mui/material";
import { useGetIdentity } from "../../hooks/useGetIdentity";
import { SupplierDashboard } from "./supplierDashboard";
import { WriterDashboard } from "./writerDashboard";
import { AdvertiserDashboard } from "./advertiserDashboard";
import { ROLE_WRITER } from "../../models/role-model";
import { ROLE_SUPPLIER } from "../../models/role-model";
import { ROLE_ADVERTISER } from "../../models/role-model";
import { useGetOne, RecordContextProvider } from "react-admin";

const SLAVA_ID = 158;

export const Dashboard = () => {
  const { data: identity } = useGetIdentity();
  const role = identity?.role;

  const currentUser = useGetOne(
    "v1_ContactBase",
    {
      id: identity?.id,
    },
    { enabled: !!identity }
  );

  const assistant = useGetOne(
    "v1_SystemUserBase",
    {
      id: getAssistantId(),
    },
    { enabled: !!currentUser.data }
  );

  function getAssistantId() {
    switch (identity?.role) {
      case ROLE_WRITER:
        return currentUser?.data?.owner_id;
      case ROLE_SUPPLIER:
        return SLAVA_ID;
      case ROLE_ADVERTISER:
        return SLAVA_ID;
    }
  }

  if (currentUser.isLoading) return <CircularProgress />;

  const ctx = { assistant, currentUser };

  function dashboardTpl() {
    switch (role) {
      case ROLE_WRITER:
        return <WriterDashboard />;
      case ROLE_SUPPLIER:
        return <SupplierDashboard />;
      case ROLE_ADVERTISER:
        return <AdvertiserDashboard />;
      default:
        return <CircularProgress />;
    }
  }
  return (
    <RecordContextProvider value={ctx}>{dashboardTpl()}</RecordContextProvider>
  );
};
