import {
    Box,
    Button,
    Checkbox,
    ClickAwayListener,
    Divider,
    Grow,
    IconButton,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import TuneIcon from '@mui/icons-material/Tune';

import { useRef, useState } from 'react';
import { FilterProps } from './filters/filters';

const MenuFilterItem = ({ filter, handleCheck }: any) => {
    
    return (
        <Box>
            <MenuItem
                dense
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: 2,
                    backgroundColor: filter.display
                        ? 'transparent'
                        : 'background.default',
                }}
            >
                <Typography display="flex" alignItems="center" gap={2}>
                    {filter.icon && (
                        <img src={filter.icon} alt={filter.label} />
                    )}
                    {filter.label}
                </Typography>
                <Checkbox
                    checked={filter.display}
                    onChange={() => handleCheck(filter.name)}
                />
            </MenuItem>
            <Divider
                sx={{
                    margin: '0 !important',
                }}
            />
        </Box>
    );
};

interface MarketPlaceMenuFiltersProps {
    filters: Array<FilterProps>;
    setFilters: Function;
}

export const MarketPlaceMenuFilters = ({
    filters,
    setFilters,
}: MarketPlaceMenuFiltersProps) => {
    const [open, setOpen] = useState(false);
    const anchorRef = useRef<HTMLButtonElement>(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: Event | React.SyntheticEvent) => {
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
        ) {
            return;
        }

        setOpen(false);
    };

    const handleCheck = (filterName: string) => {
        // find filter in filters list, set display to true or false and update filters list
        const newFilters = filters.map((filter) => {
            if (filter.name === filterName) {
                filter.display = !filter.display;
            }
            return filter;
        });
        setFilters(newFilters);
    };

    return (
        <Box>
            <Button
                ref={anchorRef}
                id="filter-menu-button"
                aria-controls={open ? 'filter-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                startIcon={<TuneIcon />}
            >
                Add Criteria
            </Button>
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="bottom-start"
                transition
                disablePortal
                sx={{
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                    boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.05)',
                }}
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom-start'
                                    ? 'left top'
                                    : 'left bottom',
                        }}
                    >
                        <Paper elevation={3}>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList
                                    autoFocusItem={open}
                                    id="filter-menu"
                                    aria-labelledby="filter-button"
                                >
                                    <MenuItem
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontWeight: 'bold',
                                                fontSize: '1.1rem',
                                            }}
                                        >
                                            Edit Criteria
                                        </Typography>
                                        <IconButton onClick={handleClose}>
                                            <CloseIcon />
                                        </IconButton>
                                    </MenuItem>
                                    <Divider sx={{ margin: '0 !important' }} />

                                    {filters.map((filter) => (
                                        <MenuFilterItem
                                            key={filter.name}
                                            filter={filter}
                                            handleCheck={handleCheck}
                                        />
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </Box>
    );
};
