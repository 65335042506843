import { nhost } from "../lib/nhost";
import { getPermissionsFromRoles } from "@react-admin/ra-rbac";
import { roleDefinitions } from "./roleDefinitions";
import type { AuthProvider } from "react-admin";
import type { IUserIdentity } from "../models/identity-model";
import type { RoleName } from "../models/role-model";

export const authProvider: AuthProvider = {
  login: async ({ email, password }) => {
    try {
      const req = await nhost.auth.signIn({
        email,
        password,
      });

      if (
        req.error != null &&
        (req.error.status < 200 || req.error.status >= 300)
      ) {
        return Promise.reject(req.error);
      }
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  },

  logout: () => {
    nhost.auth.signOut();

    return Promise.resolve();
  },
  checkAuth: async () => {
    const isAuthenticated = await nhost.auth.isAuthenticatedAsync();

    return isAuthenticated ? Promise.resolve() : Promise.reject();
  },

  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      return Promise.reject();
    }
    // other error code (404, 500, etc): no need to log out
    return Promise.resolve();
  },
  getIdentity: (): Promise<IUserIdentity> => {
    const nhostUser = nhost.auth.getUser();

    if (nhostUser === null) return Promise.reject();

    const raUser = {
      id: Number(nhostUser.metadata.crm_userid),
      fullName: nhostUser.displayName,
      email: nhostUser.email,
      roles: nhostUser.roles as RoleName[],
      role: nhostUser.defaultRole as RoleName,
    };

    return Promise.resolve(raUser);
  },

  getPermissions: (): Promise<any> => {
    // In this app the user can only select 1 role at a time,
    // so we compute the permissions based on role rather than roles.
    const hasuraClaims = nhost.auth.getHasuraClaims();

    if (hasuraClaims === null) return Promise.reject();

    const role = hasuraClaims["x-hasura-default-role"];
    const userRoles = role ? [role] : [];
    const permissions = getPermissionsFromRoles({
      roleDefinitions,
      userRoles,
    });

    return Promise.resolve(permissions);
  },
};
