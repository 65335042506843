import {
    Datagrid,
    List,
    NumberField,
    ReferenceField,
    TextField,
    DatagridProps,
} from "react-admin";

import React from "react";
import { Typography, Stack, Card, CardContent } from '@mui/material';
import LinkInNewWindowField from "../common/linkInNewWindowField";


export const MarketplaceCard = (props: DatagridProps) => {
    return (
        <Card>
            <CardContent>
                <Stack spacing={2}>
                    <Typography variant="h3">Market PLace</Typography>
                    <List
                        title="My Offers"
                        resource="v1_Offer"
                        sort={{ field: 'Status', order: 'DESC' }}
                        perPage={6}
                        pagination={false}                    >
                        <Datagrid bulkActionButtons={false} rowClick="edit" {...props}>
                            <NumberField label ="Price" source="link_price" />
                            <LinkInNewWindowField
                                propertyName="website_name"
                                label="Offer Name"
                                showPropertyName={true}
                            />
                            <ReferenceField
                                label="Languages"
                                source="LanguageId"
                                reference="v1_new_languagesBase"
                                link="show"
                            >
                                <TextField source="new_name" />
                            </ReferenceField>
                            <ReferenceField
                                label="ahrefs DR"
                                source="website_id"
                                reference="v1_Website"
                                link="show"
                            >
                                <NumberField source="AhrefsDR" />
                            </ReferenceField>
                            <ReferenceField
                                label="ahrefs DR"
                                source="website_id"
                                reference="v1_Website"
                                link="show"
                            >
                                <NumberField source="AhrefsDR" />
                            </ReferenceField>
                            <ReferenceField
                                label="ahrefs DR"
                                source="website_id"
                                reference="v1_Website"
                                link="show"
                            >
                                <NumberField source="AhrefsDR" />
                            </ReferenceField>
                            <ReferenceField
                                label="ahrefs DR"
                                source="website_id"
                                reference="v1_Website"
                                link="show"
                            >
                                <NumberField source="AhrefsDR" />
                            </ReferenceField>
                            {/*  <ReferenceInput
            source="NicheId"
            reference="v1_MarketPlaceOfferNiche"
        >
            <BooleanInput
                source="isCBDAccepted"
                label="CDB"
            />
        </ReferenceInput>*/}
                        </Datagrid>
                    </List>
                </Stack>
            </CardContent>
        </Card>
    );
};
