import { Search } from '@mui/icons-material';
import { InputAdornment, TextField } from '@mui/material';
import { useState } from 'react';
import { useListContext } from 'react-admin';

const SearchFilter = () => {
    const [search, setSearch] = useState('');
    const { setFilters } = useListContext();
    const handleChange = (event: any) => {
        setFilters({ website_name: event.target.value }, []);
        setSearch(event.target.value);
    };

    return (
        <TextField
            value={search}
            onChange={handleChange}
            fullWidth
            InputLabelProps={{ shrink: true }}
            placeholder={`Search`}
            size='small'
            variant='standard'
            sx={{
                width: 200,
            }}
            InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
        />
    );
};

export default SearchFilter;
