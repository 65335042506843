import { Card, CardContent, Stack, Typography } from '@mui/material';
import {Datagrid, List,ListBase, ReferenceField, TextField, UrlField} from 'react-admin';
import { useGetIdentity } from '../../hooks/useGetIdentity';
import { GoToResourceButton } from '../common/goToResourceButton';
import { OfferDatagrid } from '../offer/offerDatagrid';
import {AmountField} from "../common/amountField";
import {OfferStatusField} from "../offer/offerStatusField";
import React from "react";
import LinkInNewWindowField from "../common/linkInNewWindowField";

export const MyOffersCard = () => {
  const { data: identity } = useGetIdentity();
  const supplierId = identity?.id;
  return (
    <Card>
      <CardContent>
        <Stack spacing={2}>
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h3">My Offers</Typography>
       {/*     <GoToResourceButton resource="v1_Offer" label="See All" />*/}
          </Stack>

          <List
            resource="v1_Offer"
            filter={{ supplier_id :supplierId}}
            sort={{ field: 'Status', order: 'DESC' }}
            perPage={6}
            pagination={false}
          >
          {/*  <OfferDatagrid bulkActionButtons={false} />*/}
            <Datagrid bulkActionButtons={false} rowClick="edit" >
              <TextField source="id" label="Offer ID" />
              <LinkInNewWindowField
                  propertyName="website_name"
                  label="Offer Name"
                  showPropertyName={true}
              />
              <AmountField
                  source="link_price"
                  sortBy="link_price"
                  label="Price"
                  applyColor={false}
                  strong={false}
                  displaySign={false}
              />
              <OfferStatusField label="Status" sortBy="Status" />
            </Datagrid>
          </List>
        </Stack>
      </CardContent>
    </Card>
  );
};
