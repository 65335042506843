import {ChipProps} from '@mui/material';
import {useRecordContext, useGetOne} from 'react-admin';
import {StatusChip} from '../common/statusChip';

const getChipProps = (status: number): Partial<Omit<ChipProps, 'label'>> => {
    switch (status) {
        case 6:
            return {variant: 'filled', color: 'success'};
        default:
            return {variant: 'filled', color: 'warning'};
    }
};

export const AdvertiserStatusField = () => {
    const post = useRecordContext();
    const {data} = useGetOne(
        'v1_AdvertiserPostStatus',
        {id: post?.AdvertiserStatus},
        {enabled: !!post?.AdvertiserStatus}
    );
    const label = data?.Status;
    if (!label) return null;
    return (
        <StatusChip
            {...getChipProps(post?.AdvertiserStatus)}
            label={label}
            sx={{height: '20px'}}
        />
    );
};
