import { green, grey } from "@mui/material/colors";

const THEMES = {
    DEFAULT: "DEFAULT",
};

const defaultVariant = {
    name: THEMES.DEFAULT,
    palette: {
        mode: "light",
        primary: {
            main: "#039BE5",
            contrastText: "#FFF",
        },
        text: {
            primary: "#02293B",
            secondary: "#02293B",
        },
        secondary: {
            main: "#4CAF50",
            contrastText: "#FFF",
        },
        neutral: {
            main: "#626262",
            secondary: "#007DBA",
        },
        warning: {
            main: "#B71C1C",
        },
        success: {
            main: "#4CAF50",
            contrastText: "#FFF",
        },
        background: {
            default: "#F7F9FC",
            paper: "#FFF",
            highlighted: { primary: "#e2f7fe", secondary: "#fff5e4" },
        },
        border: {
            color: 'rgba(0, 0, 0, 0.23)',
        }
    },
    header: {
        color: grey[500],
        background: "#FFF",
        search: {
            color: grey[800],
        },
        indicator: {
            background: "#039BE5",
        },
    },
    footer: {
        color: grey[500],
        background: "#FFF",
    },
    sidebar: {
        color: grey[200],
        background: "#02293C",
        header: {
            color: grey[200],
            background: "#02293C",
            brand: {
                color: "#039BE5",
            },
        },
        footer: {
            color: grey[200],
            background: "#1E2A38",
            online: {
                background: green[500],
            },
        },
        badge: {
            color: "#FFF",
            background: "#039BE5",
        },
    },
};

const variants: Array<VariantType> = [defaultVariant];

export default variants;

export type VariantType = {
    name: string;
    palette: {
        primary: MainContrastTextType;
        secondary: MainContrastTextType;
        warning: { main: string };
        background: {
            default: string;
            paper: string;
            highlighted: { primary: string; secondary: string };
        };
    };
    header: ColorBgType & {
        search: {
            color: string;
        };
        indicator: {
            background: string;
        };
    };
    footer: ColorBgType;
    sidebar: ColorBgType & {
        header: ColorBgType & {
            brand: {
                color: string;
            };
        };
        footer: ColorBgType & {
            online: {
                background: string;
            };
        };
        badge: ColorBgType;
    };
};

type MainContrastTextType = {
    main: string;
    contrastText: string;
};
type ColorBgType = {
    color: string;
    background: string;
};
