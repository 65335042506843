import { Box, Stack, Typography } from "@mui/material";
import { DateField, ReferenceField, TextField, useRecordContext } from "react-admin";
import { MyOffers } from "./myOffers";
import { AdvertiserLinksBought } from "./advertiserLinksBought";
import { AdvertiserLinksStatus } from "./advertiserLinksStatus";
import { OnlineAssistant } from "./onlineAssistant";
import { MarketplaceCard } from "./marketplaceCard";
import { Payouts } from "./payouts";
import { PostListCard } from "../post/PostListCard";
import { ORDER_STATUS_VALUE } from "../../config/statuses";
import { PostStatusField } from "../post/PostStatusField";

const PostListFields = [
    <ReferenceField
        label="Order Status"
        source="new_ContentOrderStatus"
        reference="v1_PostsContentStatus"
        link={false}
        key={1}
    >
        <PostStatusField />
    </ReferenceField>,
    <DateField
        source="ordered_on"
        label="Ordered On"
        locales="en-GB"
        key={2}
    />,
    <TextField source="id" label="Post ID" key={3} />,
];

export const AdvertiserDashboard = () => {
    const { currentUser } = useRecordContext();
    return (
        <Stack pb={8}>
            <Typography variant="h1" my={4}>
                Hi, {currentUser.data.FullName}
            </Typography>
            <Box display="grid" gridTemplateColumns="repeat(18, 1fr)" gap={4}>
                <Box gridColumn="span 13" gridRow="span 3">
                <PostListCard
                        title="Orders"
                        filterForRole={{
                            AdvertiserId: currentUser.data.id,
                        }}
                        statusToFilter={[
                            ORDER_STATUS_VALUE.ORDERED,
                            ORDER_STATUS_VALUE.DECLINED,
                        ]}
                        redirectToListButton
                        sort={{ field: "ordered_on", order: "DESC" }}
                        displayOnDashboard
                    >
                        {PostListFields}
                    </PostListCard>
                </Box>
                <Box gridColumn="span 5">
                    <AdvertiserLinksBought />
                </Box>
                <Box gridColumn="span 5">
                    <AdvertiserLinksStatus />
                </Box>
                
                <Box gridColumn="span 5">
                    <MyOffers />
                </Box>
                <Box gridColumn="span 5">
                    <Payouts />
                </Box>
                <Box gridColumn="span 5">
                    <OnlineAssistant  />
                </Box>
                <Box gridColumn="span 13">
                    <MarketplaceCard />
                </Box>
            </Box>
        </Stack>
    );
};
