import {
  RaRecord,
  useGetList,
  UseGetListHookValue,
  useRecordContext,
  useResourceContext,
} from 'react-admin';
import { EventRecord } from '@react-admin/ra-audit-log';
import merge from 'lodash/merge';

// Duplicate of RA's useRecordEvents hook with filter support
export const useRecordEvents = (
  options: UseRecordEventsOptions = {}
): UseGetListHookValue<EventRecord> => {
  const record = useRecordContext(options);
  const resource = useResourceContext(options);
  const {
    page = 1,
    perPage = 25,
    sort: field = 'date',
    order = 'DESC',
    eventResource = 'events',
    filter = {},
  } = options;
  const mergedFilter = merge(
    {
      resource,
      payload: {
        data: {
          id: record?.id,
        },
      },
    },
    filter
  );

  return useGetList<EventRecord>(
    eventResource,
    {
      pagination: {
        page,
        perPage,
      },
      sort: {
        field,
        order,
      },
      filter: mergedFilter,
    },
    {
      enabled: !!record,
    }
  );
};

export interface UseRecordEventsOptions {
  page?: number;
  perPage?: number;
  sort?: string;
  order?: string;
  record?: RaRecord;
  resource?: string;
  eventResource?: string;
  filter?: any;
}
