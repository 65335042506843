import { createContext, useState, useEffect } from 'react';

const LOCAL_STORAGE_CART_ITEMS = 'adzz-cart-items';

export interface CartItem {
    id: string;
    label: string;
    quantity: number;
    price: number;
    currency: string;
}

interface CartContextProps {
    cartItems: CartItem[];
    increaseCartItems: (cartItem: CartItem) => void;
    decreaseCartItems: (id: string) => void;
    getCartItem: (id: string) => CartItem | undefined;
    getSubTotalByCurrency: () => Record<
        string,
        { quantity: number; total: number }
    >;
}

// Create a context to store cart items
export const CartContext = createContext<CartContextProps>({
    cartItems: [] as CartItem[],
    increaseCartItems: () => {},
    decreaseCartItems: () => {},
    getCartItem: () => undefined,
    getSubTotalByCurrency: () => ({}),
});

export function increaseItem(cartItem: CartItem, cartItems: CartItem[]) {
    const existingCartItemIndex = cartItems.findIndex(
        (item) => item.id === cartItem.id,
    );

    if (existingCartItemIndex === -1) {
        return [...cartItems, cartItem];
    }

    const updatedCartItems = [...cartItems];
    updatedCartItems[existingCartItemIndex].quantity += cartItem.quantity;
    return updatedCartItems;
}

export function decreaseItem(itemId: string, cartItems: CartItem[]) {
    // if item already exists in cart, update quantity if quantity > 1 else remove item from cart
    const existingCartItemIndex = cartItems.findIndex(
        (item) => item.id === itemId,
    );

    if (existingCartItemIndex === -1) {
        return cartItems;
    }

    const updatedCartItems = [...cartItems];
    if (updatedCartItems[existingCartItemIndex].quantity > 1) {
        updatedCartItems[existingCartItemIndex].quantity -= 1;
        return updatedCartItems;
    } else {
        updatedCartItems.splice(existingCartItemIndex, 1);
        return updatedCartItems;
    }
}

// Create a CartContextProvider component
export const CartContextProvider = ({ children }: any) => {
    const [cartItems, setCartItems] = useState<CartItem[]>([]);

    useEffect(() => {
        const storedCartItems = localStorage.getItem(LOCAL_STORAGE_CART_ITEMS);
        if (storedCartItems) {
            setCartItems(JSON.parse(storedCartItems));
        }
    }, []);


    const saveToLocalStorage = (cartItems: CartItem[]) => {
        localStorage.setItem(
            LOCAL_STORAGE_CART_ITEMS,
            JSON.stringify(cartItems),
        );
    };

    const increaseCartItems = (cartItem: CartItem) => {
        const newCartItems = increaseItem(cartItem, cartItems);
        setCartItems(newCartItems);
        saveToLocalStorage(newCartItems)
    };

    const decreaseCartItems = (itemId: string) => {
        const newCartItems = decreaseItem(itemId, cartItems);
        setCartItems(newCartItems);
        saveToLocalStorage(newCartItems)
    };

    const getCartItem = (itemId: string) => {
        return cartItems.find((item) => item.id === itemId);
    };

    const getSubTotalByCurrency = () => {
        // for each currency, sum up the total, sum up the total quantity, and return an array of objects
        // [{currency: 'USD', total: 100, quantity: 2}, {currency: 'EUR', total: 200, quantity: 3}]
        const subTotalByCurrency: Record<string, any> = {};
        cartItems.forEach((item) => {
            if (!subTotalByCurrency[item.currency]) {
                subTotalByCurrency[item.currency] = {
                    total: 0,
                    quantity: 0,
                };
            }
            subTotalByCurrency[item.currency].total +=
                item.price * item.quantity;
            subTotalByCurrency[item.currency].quantity += item.quantity;
        });
        return subTotalByCurrency;
    };

    return (
        <CartContext.Provider
            value={{
                cartItems,
                increaseCartItems,
                decreaseCartItems,
                getCartItem,
                getSubTotalByCurrency,
            }}
        >
            {children}
        </CartContext.Provider>
    );
};
