import { createElement } from "react";
import { HorizontalMenu } from "@react-admin/ra-navigation";
import { tabClasses } from "@mui/material/Tab";
import { Theme } from "@emotion/react";
import { Badge, SxProps } from "@mui/material";
import {
    useCreatePath,
    useGetList,
    useGetResourceLabel,
    usePermissions,
    useResourceDefinitions,
} from "react-admin";
import { canAccess } from "@react-admin/ra-rbac";
import { useGetIdentity } from "../hooks/useGetIdentity";
import { ORDER_STATUS_VALUE } from "../config/statuses";
import {
    ROLE_ADVERTISER,
    ROLE_SUPPLIER,
    ROLE_WRITER,
} from "../models/role-model";
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';

const TabIndicatorSx: SxProps = (theme: Theme) => ({
    height: "32px",
    zIndex: -1,
    transform: "translate(0, -4px)",
});

const MenuList = () => {
    const resources = useResourceDefinitions();
    
    const getResourceLabel = useGetResourceLabel();
    const createPath = useCreatePath();
    const { permissions } = usePermissions();

    const { data: identity } = useGetIdentity();
    const role = identity?.role;
    let writerId;
    let supplierId;
    let advertiserId;
    let filterForRole;

    switch (role) {
        case ROLE_WRITER:
            writerId = identity?.id;
            filterForRole = { writer_id: writerId };
            break;
        case ROLE_SUPPLIER:
            supplierId = identity?.id;
            filterForRole = { supplier_id: supplierId };
            break;
        case ROLE_ADVERTISER:
            advertiserId = identity?.id;
            filterForRole = { AdvertiserId: advertiserId };
            break;
    }

    const requiringAttentionStatus = [ORDER_STATUS_VALUE.ORDERED, ORDER_STATUS_VALUE.DECLINED];

    const { data: requiringAttentionOrders } = useGetList("v1_Posts", {
        filter: {
            ...filterForRole,
            "ContentStatus#value@_in": requiringAttentionStatus,
        },
    });

    return (
        <HorizontalMenu
            sx={{
                [`& .${tabClasses.root}`]: {
                    opacity: 1,
                    fontSize: "0.9rem",
                    fontWeight: 500,
                    lineHeight: 1,
                },
            }}
            TabIndicatorProps={{
                sx: TabIndicatorSx,
            }}
        >
            <HorizontalMenu.Item
                        to='/'
                        value={''}
                        label="Dashboard"
                        icon={<DashboardOutlinedIcon />}
                        iconPosition="start"
                    />
            {Object.keys(resources)
                .filter((name) =>
                    canAccess({
                        permissions,
                        action: "list",
                        resource: name,
                    })
                )
                .map((name) => (
                    <HorizontalMenu.Item
                        key={name}
                        to={createPath({
                            resource: name,
                            type: "list",
                        })}
                        value={name}
                        label={
                            (resources[name].options?.labelByRole &&
                                role &&
                                resources[name].options.labelByRole[role]) ||
                            resources[name].options?.label ||
                            getResourceLabel(name, 2)
                        }
                        icon={
                            resources[name].icon &&
                            name === "v1_Posts" &&
                            requiringAttentionOrders &&
                            requiringAttentionOrders.length > 0 ? (
                                <Badge
                                    color="warning"
                                    // Use variant="dot" if you need a notification dot
                                    variant="standard" 
                                    overlap="circular"
                                    children={createElement(
                                        resources[name].icon
                                    )}
                                />
                            ) : resources[name].icon ? (
                                createElement(resources[name].icon)
                            ) : undefined
                        }
                        iconPosition="start"
                    />
                ))}
        </HorizontalMenu>
    );
};

export default MenuList;
