import { Box, SvgIcon, Typography } from '@mui/material';
import { FunctionField } from 'react-admin';
import NicheBitcoinIcon from '../../../layout/icons/NicheBitcoinIcon';
import NicheCBDIcon from '../../../layout/icons/NicheCBDIcon';
import NicheAdultIcon from '../../../layout/icons/NicheAdultIcon';
import NicheCasinoIcon from '../../../layout/icons/NicheCasinoIcon';
import NicheFinanceIcon from '../../../layout/icons/NicheFinanceIcon';

const Niche = ({
    accepted,
    icon,
    multiply,
}: {
    accepted: boolean;
    icon: any;
    multiply: number;
}) => {
    const IconComponent = icon;
    return (
        <Box position="relative" display="flex" justifyContent="center">
            <SvgIcon
                viewBox="0 0 20 20"
                sx={{
                    color: accepted ? 'primary.main' : 'neutral.main',
                }}
            >
                <IconComponent fontSize="large" />
            </SvgIcon>
            {accepted && (
                <Typography
                    sx={{
                        fontSize: '0.8rem',
                        fontWeight: 'bold',
                        color: 'primary.main',
                        position: 'absolute',
                        bottom: -5,
                    }}
                >
                    x{multiply}
                </Typography>
            )}
        </Box>
    );
};

const NicheField = () => {
    return (
        <FunctionField
            render={(record?: any) => {
                if (!record) return null;
                return (
                    <Box
                        display="flex"
                        justifyContent="center"
                        gap={4}
                        minHeight={30}
                    >
                        <Niche
                            accepted={record.isCryptoAccepted}
                            icon={NicheBitcoinIcon}
                            multiply={record.CryptoMultiply}
                        />
                        <Niche
                            accepted={record.isCBDAccepted}
                            icon={NicheCBDIcon}
                            multiply={record.CBDMultiply}
                        />
                        <Niche
                            accepted={record.isAdultAccepted}
                            icon={NicheAdultIcon}
                            multiply={record.AdultMultiplier}
                        />
                        <Niche
                            accepted={record.isCasinoAccepted}
                            icon={NicheCasinoIcon}
                            multiply={record.CasinoMultiply}
                        />
                        <Niche
                            accepted={record.isFinanceAccepted}
                            icon={NicheFinanceIcon}
                            multiply={record.FinanceMultiply}
                        />
                    </Box>
                );
            }}
        />
    );
};

export default NicheField;
