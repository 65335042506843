import { Box } from '@mui/material';
import { useRecordContext } from 'react-admin';
import PageTitle from '../../layout/PageTitle';

const OrderEditTitle = () => {
    const record = useRecordContext();

    if (!record) return null;

    return (
        <Box sx={{ marginBottom: 7 }}>
            <PageTitle>Ordered Post - #{record.id}</PageTitle>
        </Box>
    );
};
export default OrderEditTitle;
