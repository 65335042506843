import * as React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { Typography } from '@mui/material';
import { useInput } from 'react-admin';

interface RangeSliderProps {
    source: string;
    icon?: string;
    label: string;
    range: { min: number; max: number };
}

export default function RangeSlider({ source, icon, label, range }: RangeSliderProps) {
    const { field: fieldGte } = useInput({
        source: `${source}@_gte`,
        defaultValue: range.min || 0,
    });

    const { field: fieldLte } = useInput({
        source: `${source}@_lte`,
        defaultValue: range.max || 100,
    });

    const handleChange = (event: Event, newValue: any) => {
        fieldGte.onChange(newValue[0]);
        fieldLte.onChange(newValue[1]);
    };

    return (
        <Box sx={{ width: 370, paddingX: 3 }}>
            <Box display={'flex'} gap={2}>
                <Typography display="flex" alignItems="center" gap={2}>
                    {icon && <img src={icon} alt={label} />}
                    {label}
                </Typography>
                <Typography fontWeight={600}>
                    {`${fieldGte.value}-${fieldLte.value}`}
                </Typography>
            </Box>
            <Slider
                getAriaLabel={() => label}
                value={[fieldGte.value, fieldLte.value]}
                onChange={handleChange}
                valueLabelDisplay="auto"
                min={range.min}
                max={range.max}
            />
        </Box>
    );
}
