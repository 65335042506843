import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import { addEventsForMutations } from '@react-admin/ra-audit-log';
import { useState } from 'react';
import { Admin, DataProvider, Loading, Resource } from 'react-admin';
import { QueryClient } from 'react-query';
import './App.css';
import { authProvider } from './authorization/authProvider';
import { Dashboard } from './components/dashboard/dashboard';
import LoginForm from './components/login/LoginForm';
import { OfferList } from './components/offer/offerList';
import OfferEdit from './components/offer/offerEdit';
import OrderEdit from './components/order/orderEdit';
import { PostList } from './components/post/postList';
import useHasuraDataProvider from './data/useHasuraDataProvider';
import i18nProvider from './i18n/i18nProvider';
import MyLayout from './layout/layout';
import createTheme from './theme';
import {
    ROLE_ADVERTISER,
    ROLE_SUPPLIER,
    ROLE_WRITER,
} from './models/role-model';
import { CartContextProvider } from './context/CartContext';
import { Resource as RBacResource} from "@react-admin/ra-rbac";


const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: process.env.NODE_ENV === 'development' ? 0 : undefined,
        },
    },
});

const App = () => {
    const [dataProvider, setDataProvider] = useState<DataProvider | null>(null);

    useHasuraDataProvider(setDataProvider);

    if (!dataProvider) return <Loading />;

    const augmentedDataProvider = addEventsForMutations(
        dataProvider,
        authProvider,
        {
            name: 'v1_Events',
            resources: ['v1_Posts'],
        },
    );

    return (
        <CartContextProvider>
            <Admin
                theme={createTheme('DEFAULT')}
                authProvider={authProvider}
                dataProvider={augmentedDataProvider}
                loginPage={LoginForm}
                layout={MyLayout}
                dashboard={Dashboard}
                queryClient={queryClient}
                i18nProvider={i18nProvider}
                requireAuth
            >
                <RBacResource
                    name="v1_Offer"
                    options={{
                        label: 'Marketplace',
                        labelByRole: {
                            [ROLE_ADVERTISER]: 'Marketplace',
                            [ROLE_SUPPLIER]: 'My Offers',
                        },
                    }}
                    icon={StorefrontOutlinedIcon}
                    list={OfferList}
                    edit={OfferEdit}
                />
                <Resource
                    name="v1_Posts"
                    options={{
                        label: 'Orders',
                        labelByRole: {
                            [ROLE_ADVERTISER]: 'Posts',
                            [ROLE_SUPPLIER]: 'Orders',
                            [ROLE_WRITER]: 'Orders',
                        },
                    }}
                    icon={AssignmentOutlinedIcon}
                    list={PostList}
                    edit={OrderEdit}
                />
            </Admin>
        </CartContextProvider>
    );
};

export default App;
