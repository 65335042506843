const NicheFinanceIcon = () => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M19 7.61876C19.1313 7.61879 19.2614 7.59418 19.3827 7.54633C19.5041 7.49848 19.6143 7.42832 19.7072 7.33988C19.8001 7.25143 19.8737 7.14643 19.924 7.03086C19.9742 6.9153 20 6.79144 20 6.66636V3.80915C20.0001 3.60931 19.9341 3.41452 19.8114 3.25241C19.6887 3.09031 19.5155 2.96912 19.3164 2.90604L10.3164 0.0488291C10.111 -0.0162764 9.88899 -0.0162764 9.68359 0.0488291L0.68359 2.90604C0.484493 2.96912 0.311309 3.09031 0.188606 3.25241C0.0659032 3.41452 -8.61316e-05 3.60931 8.43742e-08 3.80915V6.66636C-3.40751e-05 6.79144 0.0258085 6.9153 0.0760511 7.03086C0.126294 7.14643 0.199952 7.25143 0.292817 7.33988C0.385683 7.42832 0.495935 7.49848 0.617276 7.54633C0.738617 7.59418 0.868669 7.61879 1 7.61876H2V14.4611C1.41674 14.6568 0.911365 15.0196 0.553267 15.4997C0.195169 15.9799 0.00190247 16.5538 8.43742e-08 17.1428V19.0476C-3.40751e-05 19.1727 0.0258085 19.2965 0.0760511 19.4121C0.126294 19.5277 0.199952 19.6327 0.292817 19.7211C0.385683 19.8096 0.495935 19.8797 0.617276 19.9276C0.738617 19.9754 0.868669 20 1 20H19C19.1313 20 19.2614 19.9754 19.3827 19.9276C19.5041 19.8797 19.6143 19.8096 19.7072 19.7211C19.8001 19.6327 19.8737 19.5277 19.924 19.4121C19.9742 19.2965 20 19.1727 20 19.0476V17.1428C19.9981 16.5538 19.8048 15.9799 19.4467 15.4997C19.0886 15.0196 18.5833 14.6568 18 14.4611V7.61876H19ZM18 18.0952H2V17.1428C2.00026 16.8903 2.10571 16.6482 2.29319 16.4696C2.48066 16.2911 2.73487 16.1906 3 16.1904H17C17.2651 16.1906 17.5193 16.2911 17.7068 16.4696C17.8943 16.6482 17.9997 16.8903 18 17.1428V18.0952ZM4 14.2856V7.61876H6V14.2856H4ZM8 14.2856V7.61876H12V14.2856H8ZM14 14.2856V7.61876H16V14.2856H14ZM2 5.71395V4.49554L10 1.95548L18 4.49554V5.71395H2Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default NicheFinanceIcon;
