import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CopyrightIcon from '@mui/icons-material/Copyright';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import SyncIcon from '@mui/icons-material/Sync';
import { SxProps } from '@mui/material';
import { EventRecord } from '@react-admin/ra-audit-log';

export const EventIcon = ({ record }: { record: EventRecord }) => {
  const sx: SxProps = { width: 16, height: 16 };
  switch (record.action) {
    case 'update':
      return <SyncIcon sx={sx} />;

    case 'Decline':
      return <CancelIcon sx={sx} color="warning" />;

    case 'Approve':
      return <CheckCircleIcon sx={sx} color="success" />;

    case 'copyscape':
      return <CopyrightIcon sx={sx} color="primary" />;

    case 'PostLinkCheck':
      return <PlaylistAddCheckIcon sx={sx} color="primary" />;

    case 'PostChangeStatus':
      return <SyncIcon sx={sx} />;

    default:
      return null;
  }
};
