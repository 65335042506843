import { SyntheticEvent, useState } from "react";
import { useGetIdentity } from "../../hooks/useGetIdentity";
import { CircularProgress, Stack, Tab } from "@mui/material";
import PageTitle from "../../layout/PageTitle";
import { PostListCard } from "./PostListCard";
import {
  DateField,
  NumberField,
  ReferenceField,
  TextField,
  WrapperField,
} from "react-admin";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { ORDER_STATUS_VALUE } from "../../config/statuses";
import LinkInNewWindowField from "../common/linkInNewWindowField";
import { TextFieldWithTooltip } from "../common/TextFieldWithTooltip";

enum TabValues {
  ORDERED = "Ordered",
  DECLINED = "Declined",
  WAITING_APPROVAL = "Waiting Approval",
  APPROVED = "Approved",
}

const getPostListFields = (tab: TabValues) => {
  const dateFileds = {
    [TabValues.ORDERED]: { source: "ordered_on", label: "Ordered On" },
    [TabValues.DECLINED]: { source: "new_DeclineDate", label: "Declined On" },
    [TabValues.WAITING_APPROVAL]: {
      source: "new_SenttoApproval",
      label: "Sent to Approval On",
    },
    [TabValues.APPROVED]: { source: "new_DateApproved", label: "Approved On" },
  };

  return [
    <ReferenceField
      label="Order ID"
      reference="v1_Posts"
      source="id"
      link="edit"
      key="id"
    >
      <TextField source="id" />
    </ReferenceField>,
    <DateField
      label={dateFileds[tab].label}
      source={dateFileds[tab].source}
      locales="en-GB"
      key="dateField"
    />,
    <DateField
      label="Due Date"
      source="new_Contentduedate"
      locales="en-GB"
      key="new_Contentduedate"
    />,
    <WrapperField
      label="Content Price"
      sortBy="new_ContentCost"
      key="new_ContentCost"
    >
      <ReferenceField
        reference="v1_TransactionCurrencyBase"
        source="currency_id"
      >
        <TextField source="CurrencySymbol" sx={{ marginInlineEnd: 1 }} />
      </ReferenceField>
      <NumberField source="new_ContentCost" />
    </WrapperField>,
    <WrapperField
      label="Upload Price"
      sortBy="new_UploadPrice"
      key="new_UploadPrice"
    >
      <ReferenceField
        reference="v1_TransactionCurrencyBase"
        source="currency_id"
      >
        <TextField source="CurrencySymbol" sx={{ marginInlineEnd: 1 }} />
      </ReferenceField>
      <NumberField source="new_UploadPrice" />
    </WrapperField>,
    <WrapperField
      label="Total Price"
      sortBy="comp_total_price_writer"
      key="comp_total_price_writer"
    >
      <ReferenceField
        reference="v1_TransactionCurrencyBase"
        source="currency_id"
      >
        <TextField source="CurrencySymbol" sx={{ marginInlineEnd: 1 }} />
      </ReferenceField>
      <NumberField source="comp_total_price_writer" />
    </WrapperField>,
    <ReferenceField
      label="Publisher Site"
      reference="v1_Website"
      source="website_id"
      link={false}
      key="website_id"
    >
      <LinkInNewWindowField
        label="Publisher Site"
        propertyName="new_name"
        showPropertyName={true}
      />
    </ReferenceField>,
    <ReferenceField
      label="Project"
      reference="v1_Project"
      source="_Project"
      link={false}
      emptyText="No Project"
      key="new_name"
    >
      <TextFieldWithTooltip
        source="new_name"
        sx={{
          fontWeight: "600",
        }}
      />
    </ReferenceField>,
    <TextFieldWithTooltip
      label="H1 Title"
      source="new_Title"
      key="new_Title"
    />,
  ];
};

export const PostList = () => {
  const [tabValue, setTabValue] = useState<TabValues>(TabValues.ORDERED);

  const handleTabChange = (_: SyntheticEvent, newValue: TabValues) => {
    setTabValue(newValue);
  };

  const { data: identity } = useGetIdentity();
  const role = identity?.role;
  let writerId;
  let supplierId;
  let advertiserId;
  let filterForRole;

  switch (role) {
    case "Writer":
      writerId = identity?.id;
      filterForRole = { writer_id: writerId };
      break;
    case "Supplier":
      supplierId = identity?.id;
      filterForRole = { supplier_id: supplierId };
      break;
    case "Advertiser":
      advertiserId = identity?.id;
      filterForRole = { AdvertiserId: advertiserId };
      break;
    default:
      return <CircularProgress />;
  }
  return (
    <TabContext value={tabValue}>
      <Stack direction="row" spacing={2} alignItems="center" mb={7}>
        <PageTitle>Orders</PageTitle>
        <TabList onChange={handleTabChange} textColor="inherit">
          <Tab value={TabValues.ORDERED} label={TabValues.ORDERED} />
          <Tab value={TabValues.DECLINED} label={TabValues.DECLINED} />
          <Tab
            value={TabValues.WAITING_APPROVAL}
            label={TabValues.WAITING_APPROVAL}
          />
          <Tab value={TabValues.APPROVED} label={TabValues.APPROVED} />
        </TabList>
      </Stack>
      <TabPanel value={TabValues.ORDERED} sx={{ padding: 0, paddingBottom: 7 }}>
        <PostListCard
          title={ORDER_STATUS_VALUE.ORDERED}
          filterForRole={filterForRole}
          statusToFilter={[ORDER_STATUS_VALUE.ORDERED]}
          sort={{ field: "ordered_on", order: "DESC" }}
        >
          {getPostListFields(TabValues.ORDERED)}
        </PostListCard>
      </TabPanel>
      <TabPanel
        value={TabValues.DECLINED}
        sx={{ padding: 0, paddingBottom: 7 }}
      >
        <PostListCard
          title={ORDER_STATUS_VALUE.DECLINED}
          filterForRole={filterForRole}
          statusToFilter={[ORDER_STATUS_VALUE.DECLINED]}
          sort={{ field: "new_DeclineDate", order: "DESC" }}
        >
          {getPostListFields(TabValues.DECLINED)}
        </PostListCard>
      </TabPanel>
      <TabPanel
        value={TabValues.WAITING_APPROVAL}
        sx={{ padding: 0, paddingBottom: 7 }}
      >
        <PostListCard
          title={ORDER_STATUS_VALUE.WAITING_APPROVAL}
          filterForRole={filterForRole}
          statusToFilter={[ORDER_STATUS_VALUE.WAITING_APPROVAL]}
          sort={{ field: "new_SenttoApproval", order: "DESC" }}
        >
          {getPostListFields(TabValues.WAITING_APPROVAL)}
        </PostListCard>
      </TabPanel>
      <TabPanel
        value={TabValues.APPROVED}
        sx={{ padding: 0, paddingBottom: 7 }}
      >
        <PostListCard
          title={ORDER_STATUS_VALUE.APPROVED}
          filterForRole={filterForRole}
          statusToFilter={[ORDER_STATUS_VALUE.APPROVED]}
          sort={{ field: "new_DateApproved", order: "DESC" }}
        >
          {getPostListFields(TabValues.APPROVED)}
        </PostListCard>
      </TabPanel>
    </TabContext>
  );
};
