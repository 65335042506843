import { Typography, Stack, Card, CardContent } from "@mui/material";
import { useRecordContext } from "react-admin";

export const MyOrders = () => {
  const { currentUser } = useRecordContext();
  const writer = currentUser.data;

  const amountFormatter = new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  });

  return (
    <Card>
      <CardContent>
        <Stack>
          <Typography variant="h3">My Orders</Typography>
          <Stack direction="row" spacing={6}>
            <Stack>
              <Typography color="text.secondary">Ordered</Typography>
              <Typography variant="h1">
                {amountFormatter.format(writer.ordered.aggregate.count)}
              </Typography>
            </Stack>
            <Stack>
              <Typography color="text.secondary">Waiting Approval</Typography>
              <Typography variant="h1" color="secondary.main">
                {amountFormatter.format(writer.waiting.aggregate.count)}
              </Typography>
            </Stack>
          </Stack>
          <Stack direction="row" spacing={6}>
            <Stack>
              <Typography color="text.secondary">Declined</Typography>
              <Typography variant="h1">
                {amountFormatter.format(writer.declined.aggregate.count)}
              </Typography>
            </Stack>
            <Stack>
              <Typography color="text.secondary">Approved</Typography>
              <Typography variant="h1" color="secondary.main">
                {amountFormatter.format(writer.approved.aggregate.count)}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};
