import { Box } from '@mui/material';
import { useRecordContext } from 'react-admin';
import PageTitle from '../../layout/PageTitle';

const OfferEditTitle = () => {
    const record = useRecordContext();

    if (!record) return null;

    return (
        <Box sx={{ marginBottom: 7 }}>
            <PageTitle>Offer - #{record.id}</PageTitle>
        </Box>
    );
};
export default OfferEditTitle;
