import { Stack } from '@mui/material';
import { EventsAndCommentsTimeline } from '../eventLog/eventsAndCommentsTimeline';

const RecordTimeLineWrapper = () => {
    return (
        <Stack
            direction="column"
            display={{ xs: 'none', md: 'block' }}
            spacing={2}
            sx={{ px: 2 }}
        >
            <EventsAndCommentsTimeline />
        </Stack>
    );
};
export default RecordTimeLineWrapper;
