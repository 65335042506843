import Box from '@mui/material/Box';
import { TextField } from '@mui/material';
import { useInput } from 'react-admin';

interface DateFilterProps {
    source: string;
    label: string;
}

export default function DateFilter({ source, label }: DateFilterProps) {
    const { field } = useInput({
        source: source,
        defaultValue: '',
    });

    const handleChange = (event: any) => {
        field.onChange(event.target.value);
    };

    return (
        <Box sx={{width: 370 }}>
            <TextField
                label={label}
                value={field.value}
                onChange={handleChange}
                fullWidth
                InputLabelProps={{ shrink: true }}
                placeholder={`Search by ${label}`}
                type='date'
            />
        </Box>
    );
}
