import { Badge, Box, IconButton, Typography } from '@mui/material';
import { FunctionField } from 'react-admin';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { useContext, useState } from 'react';

import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import { CartContext } from '../../../context/CartContext';

const currencyMapper: Record<string, string> = {
    $: 'USD',
    '€': 'EUR',
    '£': 'GBP',
    '¥': 'JPY',
    '₪': 'ILS',
};

function formatPrice(price: number, currency: string) {
    return price.toLocaleString('en-US', {
        style: 'currency',
        currency: currencyMapper[currency] || 'USD',
    });
}

const PriceField = ({ label }: { label: string; source: string }) => {
    const [displayMode, setDisplayMode] = useState<'price' | 'quantity'>(
        'price',
    );

    const { increaseCartItems, decreaseCartItems, getCartItem } =
        useContext(CartContext);

    const handleDisplayModeChange = (
        event: any,
        mode: 'price' | 'quantity',
    ) => {
        event.preventDefault();
        event.stopPropagation();
        setDisplayMode(mode);
    };

    const handleAddToCart = (event: any, record: any) => {
        event.preventDefault();
        event.stopPropagation();

        increaseCartItems({
            id: record.id,
            label: record.website_name,
            price: record.ExternalPrice,
            quantity: 1,
            currency: record.currency || '$',
        });
    };

    const handleRemoveFromCart = (event: any, record: any) => {
        event.preventDefault();
        event.stopPropagation();
        decreaseCartItems(record.id);
    };

    return (
        <FunctionField
            label={label}
            render={(record?: any) => {
                const quantity = getCartItem(record.id)?.quantity || 0;

                if (displayMode === 'quantity') {
                    return (
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <IconButton
                                onClick={(event) =>
                                    handleDisplayModeChange(event, 'price')
                                }
                                aria-label="like"
                                sx={{
                                    color: 'white',
                                }}
                            >
                                <AddShoppingCartIcon />
                            </IconButton>
                            <Box display="flex" alignItems="center">
                                <IconButton
                                    aria-label="like"
                                    onClick={(event) =>
                                        handleAddToCart(event, record)
                                    }
                                    sx={{
                                        color: 'white',
                                    }}
                                >
                                    <AddBoxOutlinedIcon />
                                </IconButton>

                                <Typography color="white" fontWeight={900}>
                                    {quantity}
                                </Typography>
                                <IconButton
                                    aria-label="like"
                                    onClick={(event) =>
                                        handleRemoveFromCart(event, record)
                                    }
                                    sx={{
                                        color: 'white',
                                    }}
                                    disabled={quantity === 0}
                                >
                                    <IndeterminateCheckBoxOutlinedIcon />
                                </IconButton>
                            </Box>
                        </Box>
                    );
                }

                return (
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Typography color="white" fontWeight={900}>
                            {formatPrice(
                                record?.ExternalPrice,
                                record?.currency,
                            )}
                        </Typography>
                        <IconButton
                            onClick={(event) =>
                                handleDisplayModeChange(event, 'quantity')
                            }
                            aria-label="like"
                            sx={{
                                color: 'white',
                            }}
                        >
                            <Badge
                                variant="dot"
                                invisible={quantity < 1}
                                color="warning"
                            >
                                <AddShoppingCartIcon />
                            </Badge>
                        </IconButton>
                    </Box>
                );
            }}
        />
    );
};

export default PriceField;
