import { EditBase, EditView } from 'react-admin';
import OfferForm from './offerForm';
import { Box } from '@mui/material';
import OfferEditTitle from './OfferEditTitle';
const OfferEdit = () => {
    return (
        <EditBase mutationMode="optimistic">
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="self-start"
                sx={{ flexDirection: { xs: 'column', md: 'row' } }}
            >
                <OfferEditTitle />
            </Box>
            <EditView>
                <OfferForm />
            </EditView>
        </EditBase>
    );
};
export default OfferEdit;
