import {
    Datagrid,
    FilterPayload,
    List,
    Pagination,
    SortPayload,
    useCreatePath,
    useGetList,
} from "react-admin";
import { Link } from "react-router-dom";
import { Box, Button, Card, Typography, useTheme } from "@mui/material";

interface PostListCardProps {
    children: React.ReactNode;
    title: string;
    filterForRole: FilterPayload;
    statusToFilter?: string[];
    requiringAttentionStatus?: string[];
    requiringAttentionSubtitle?: boolean;
    redirectToListButton?: boolean;
    sort?: SortPayload;
    displayOnDashboard?: boolean;
}

interface EmptyProps {
    status?: string[];
}

const Empty = ({ status }: EmptyProps) => {
    const emptyText = status
        ? `You don't have any ${status
              .join(", ")
              .toLowerCase()} posts at this time.`
        : "You don't have any posts at this time.";
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
            }}
        >
            <Typography
                variant="h2"
                sx={{
                    fontSize: "1.5rem",
                    lineHeight: "1.5rem",
                    color: "#007DBA",
                }}
            >
                {emptyText}
            </Typography>
        </Box>
    );
};

export const PostListCard = ({
    children,
    title,
    filterForRole,
    statusToFilter,
    requiringAttentionStatus,
    requiringAttentionSubtitle,
    redirectToListButton,
    sort,
    displayOnDashboard,
}: PostListCardProps) => {
    const theme = useTheme();
    const createPath = useCreatePath();

    const filterForStatus = {
        "ContentStatus#value@_in": statusToFilter,
    };

    const filterForRequiringAttentionStatus = {
        "ContentStatus#value@_in": requiringAttentionStatus,
    };

    const {
        total: requiringAttentionPostsNumber,
        data: requiringAttentionRecords,
    } = useGetList("v1_Posts", {
        filter: { ...filterForRole, ...filterForRequiringAttentionStatus },
    });

    const postRowStyle = (record: any) => {
        const isRequiringAttentionRecord = requiringAttentionStatus && requiringAttentionRecords?.some(
            (requiringAttentionRecord) =>
                requiringAttentionRecord?.id === record?.id
        );
        return isRequiringAttentionRecord
            ? {
                  backgroundColor: `${theme.palette.warning.main}0d`, // add 0d to make it 5% opacity
              }
            : undefined;
    };

    return (
        <Card
            sx={{
                padding: 7,
            }}
        >
            <Box display="flex" justifyContent="space-between" mb={4.5}>
                <Box
                    display="flex"
                    justifyContent="flex-start"
                    alignItems="baseline"
                >
                    <Typography
                        variant="h2"
                        sx={{
                            fontSize: "1.5rem",
                            fontWeight: "600",
                            lineHeight: "1.5rem",
                        }}
                    >
                        {title}
                    </Typography>
                    {!!requiringAttentionSubtitle &&
                        !!requiringAttentionStatus &&
                        !!requiringAttentionPostsNumber && (
                            <Typography
                                variant="h4"
                                sx={{
                                    whiteSpace: "pre-wrap",
                                    fontSize: "1.125rem",
                                    lineHeight: "1.125rem",
                                    fontWeight: "600",
                                }}
                            >
                                {" - "}
                                <Typography
                                    variant="inherit"
                                    component="span"
                                    sx={{
                                        color: theme.palette.warning.main,
                                    }}
                                >
                                    {`${requiringAttentionPostsNumber} require attention`}
                                </Typography>
                            </Typography>
                        )}
                </Box>
                {redirectToListButton && (
                    <Button
                        component={Link}
                        to={createPath({ resource: "v1_Posts", type: "list" })}
                        variant="contained"
                    >
                        View All Orders
                    </Button>
                )}
            </Box>
            <List
                disableSyncWithLocation
                actions={false}
                resource="v1_Posts"
                perPage={displayOnDashboard ? 8 : 10}
                filter={{
                    ...filterForRole,
                    ...filterForStatus,
                }}
                sort={sort}
                pagination={
                    displayOnDashboard ? (
                        false
                    ) : (
                        <Pagination rowsPerPageOptions={[10, 15]} />
                    )
                }
                empty={<Empty status={statusToFilter} />}
            >
                <Datagrid
                    bulkActionButtons={false}
                    rowClick="edit"
                    rowStyle={postRowStyle}
                >
                    {children}
                </Datagrid>
            </List>
        </Card>
    );
};
