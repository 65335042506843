import {
  Stack,
  Typography,
  Link,
  Avatar,
  CircularProgress,
} from "@mui/material";
import MailOutlinedIcon from "@mui/icons-material/MailOutlined";
import { useRecordContext } from "react-admin";

const defaultAssistantAvatar =
  "https://cherryassets.s3.eu-central-1.amazonaws.com/avatars/assistantf.png";

export const AssistantCard = () => {
  const { assistant } = useRecordContext();

  if (assistant.isLoading) return <CircularProgress />;

  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <Avatar
        variant="rounded"
        src={assistant.data.AvatarPicture || defaultAssistantAvatar}
        sx={{
          height: "100%",
          width: "auto",
          flex: "0 0 60px",
        }}
      />
      <Stack>
        <Typography
          variant="h4"
          sx={{
            fontWeight: 600,
          }}
        >
          {assistant.data.FirstName}
        </Typography>
        <Typography
          color="neutral.main"
          sx={{
            marginBottom: 3.5,
          }}
        >
          {assistant.data.JobTitle || "Project Manager"}
        </Typography>
        {assistant.data.email && (
          <Stack direction="row" alignItems="center" spacing={2}>
            <MailOutlinedIcon color="primary" />
            <Link href={`mailto:${assistant.data.email}`} color="inherit">
              {assistant.data.email}
            </Link>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};
