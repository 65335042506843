import {
    AutocompleteInput,
    NumberInput,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    TextInput,
} from 'react-admin';
import { Grid, InputAdornment } from '@mui/material';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import OfferNicheInput from './OfferNicheInput';

const OfferForm = (props: any) => {
    return (
        <SimpleForm>
            <Grid container spacing={2}>
                <Grid item xs={8}>
                    <TextInput
                        source="website_name"
                        label="URL"
                        placeholder="Write here"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={2}>
                    <NumberInput
                        source="OfferPublisherPrice"
                        label="Price"
                        placeholder="Write here"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <CreditCardIcon />
                                </InputAdornment>
                            ),
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={2}>
                    <ReferenceInput
                        label="Language"
                        source="LanguageId"
                        reference="v1_new_languagesBase"
                        perPage={1000}
                    >
                        <AutocompleteInput
                            optionText="new_name"
                            size="small"
                            placeholder="Select one"
                            filterToQuery={(searchText: string) => ({
                                [`new_name@_eq`]: searchText,
                            })}
                        />
                    </ReferenceInput>
                </Grid>
                <Grid item xs={12}>
                    <ReferenceInput
                        label="Category"
                        source="MarketPlaceCategoriesId"
                        reference="v1_MarketPlaceCategories"
                        perPage={1000}
                    >
                        <SelectInput optionText="CategoryName" fullWidth />
                    </ReferenceInput>
                </Grid>
                <Grid item xs={6}>
                    <TextInput
                        label="Offer Details"
                        source="Description"
                        multiline
                        placeholder="Write here"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextInput
                        label="Publication Sample"
                        source="PublicationSample"
                        multiline
                        placeholder="Paste a link here - optional"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <OfferNicheInput />
                </Grid>
            </Grid>
        </SimpleForm>
    );
};
export default OfferForm;
