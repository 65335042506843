import { useRef } from "react";
import type { Dispatch, SetStateAction } from "react";
import { useInput } from "react-admin";
import { Editor as TinyMCEEditor } from "tinymce";
import { Editor } from "@tinymce/tinymce-react";

export interface TinyMceProps {
  source: string;
  setWordCount: Dispatch<SetStateAction<number>>;
}

const TinyMce = ({ setWordCount, source }: TinyMceProps) => {
  const editorRef = useRef<TinyMCEEditor | null>(null);

  const { field } = useInput({ source });

  function onInit(_evt: any, editor: TinyMCEEditor) {
    editorRef.current = editor;
    setWordCount(getWordCount());
  }

  function onChange(newValue: string) {
    field.onChange(newValue);
    setWordCount(getWordCount());
  }

  function getWordCount(): number {
    return editorRef?.current?.plugins.wordcount.body.getWordCount();
  }

  return (
    <Editor
      apiKey={process.env.REACT_APP_TINY_MCE_API_KEY}
      init={{
        menubar: false,
        plugins: [
          "image",
          "link",
          "wordcount",
          "anchor",
          "code",
          "lists",
          "textcolor blocks",
          "table",
        ],
        toolbar: [
          "blocks undo redo styleselect link image media anchor bold italic underline strikethrough forecolor backcolor numlist bullist align outdent indent removeformat code table tabledelete",
        ],
        images_upload_url: `${process.env.REACT_APP_PROXY_BASE}/upload`,
        referrer_policy: "unsafe-url",
        height: 400,
      }}
      onInit={onInit}
      onEditorChange={onChange}
      textareaName={source}
      value={field.value}
    />
  );
};

export default TinyMce;
