import {Datagrid, DatagridProps, DateField, ReferenceField, TextField, UrlField} from 'react-admin';
import { AmountField } from '../common/amountField';
import { OfferStatusField } from './offerStatusField';
import React from "react";
import LinkInNewWindowField from '../common/linkInNewWindowField';
export const OfferDatagrid = (props: DatagridProps) => (
  <Datagrid rowClick="edit" {...props}>
    <TextField source="id" label="Offer ID" />
    <DateField source="created_on" label="Created on" locales="en-GB" />
      <LinkInNewWindowField
          propertyName="website_name"
          label="Offer Name"
          showPropertyName={true}
      />
    <AmountField
      source="link_price"
      sortBy="link_price"
      label="Price"
      applyColor={false}
      strong={false}
      displaySign={false}
    />
      <ReferenceField
          label="Languages"
          source="LanguageId"
          reference="v1_new_languagesBase"
          link="show"
      >
          <TextField source="new_name" />
      </ReferenceField>
    <OfferStatusField label="Status" sortBy="Status" />
  </Datagrid>
);
