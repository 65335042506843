export const POST_STATUS = {
    IN_PROGRESS: 100000000,
    POSTED: 100000003,
    POOL: 100000001,
    PLANNED: 100000002
}

export const CONTENT_STATUS = {
    PLANNED: 100000008,
    MERGED: 100000007,
    NO_NEED: 100000000,
    POOL: 100000001,
    ORDERED: 100000002,
    WAITING_APPROVAL: 100000003,
    DECLINED: 100000005,
    APPROVED: 100000004,
    CONTENT_REQUEST: 100000006,
}

export const OUTREACH_STATUS = {
    CONTENT_WRITING: 100000000,
    READY_TO_PUBLISH: 100000001,
    SENT_TO_PUBLISHER: 100000002,
    PUBLISHED: 100000003,

}

export const PURCHASE_ORDER_STATUS = {
    APPROVAL_REQUIRED: 1,
    TO_PAY: 100000000,
    PAID: 2,
    CANCELLED: 100000001,
}

export const TRACKERS_ONLINE_STATUS = {
    NOT_AVAILABLE: 100000001,
    ONLINE: 100000000
}


export const ORDER_STATUS_VALUE = {
    ORDERED: 'Ordered',
    WAITING_APPROVAL: 'Waiting Approval',
    DECLINED: 'Declined',
    APPROVED: 'Approved',
}