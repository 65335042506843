import { ListBase, ListView, Pagination } from 'react-admin';
import { Box } from '@mui/material';
import { MarketplaceDatagrid } from './marketplaceDatagrid';

import { Typography } from '@mui/material';
import PageTitle from '../../layout/PageTitle';
import { MarketPlaceFilters } from './MarketPlaceFilters';
import { MarketPlaceSelectColumnsButton } from './MarketPlaceSelectColumnsButton';
import { useMeasure } from 'react-use';
import SearchFilter from './filters/SearchFilter';

const INNER_PADDING = 32;
export const MarketplaceList = () => {
    const [ref, { width }] = useMeasure<HTMLDivElement>();

    return (
        <Box ref={ref}>
            <Box sx={{ marginBottom: 7 }}>
                <PageTitle>MarketPlace</PageTitle>
            </Box>
            <ListBase sort={{ field: 'Status', order: 'DESC' }} perPage={10}>
                <MarketPlaceFilters />
                <ListView
                    title="My Offers"
                    resource="v1_Offer"
                    pagination={<Pagination rowsPerPageOptions={[10]} />}
                    actions={false}
                >
                    <Box
                        sx={{
                            padding: 4,
                        }}
                    >
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            mb={2}
                        >
                            <Typography variant="h3">MarketPlace</Typography>
                            <Box display="flex" gap={3} alignItems="center">
                                <SearchFilter />
                                <MarketPlaceSelectColumnsButton />
                            </Box>
                        </Box>

                        <MarketplaceDatagrid width={width - INNER_PADDING} />
                    </Box>
                </ListView>
            </ListBase>
        </Box>
    );
};
