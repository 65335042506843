import {
    Typography,
    Stack,
    Card,
    CardContent,
} from '@mui/material';
import {
    Loading,
    useGetOne,
} from 'react-admin';
import React from 'react';
import { useGetIdentity } from '../../hooks/useGetIdentity';

const amountFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
});

export const Payouts = () => {
    const { data: identity } = useGetIdentity();
    const writer_id = identity?.id;
    const { data: writer, isLoading: writerLoading } = useGetOne(
        'v1_ContactBase',
        {
            id: writer_id,
        },
        { enabled: !!writer_id },
    );
    if (writerLoading) return <Loading />;
    return (
        <Card>
            <CardContent>
                <Stack>
                    <Typography variant="h3">Payouts</Typography>
                    <Stack direction="row" spacing={6}>
                        <Stack>
                            <Typography variant="h1" color="secondary.main">
                                {amountFormatter.format(writer.TotalPayoutsSum)}
                            </Typography>
                            <Typography color="text.secondary">
                                Total
                            </Typography>
                        </Stack>
                        <Stack>
                            <Typography variant="h1">
                                {amountFormatter.format(writer.Balance)}
                            </Typography>
                            <Typography color="text.secondary">
                                Current Balance
                            </Typography>
                        </Stack>
                    </Stack>
                </Stack>
            </CardContent>
        </Card>
    );
};
