import * as React from "react";
import { Typography, Stack, Card, CardContent, Tabs, Tab } from "@mui/material";
import {
    ACTIVITY_PAYOUT,
    ACTIVITY_TOP_UP,
    ActivityType,
} from "../balance/types";
import { BalanceList } from "../balance/balanceList";

interface BalanceCardProps {
    topUpLabel?: string;
    payoutLabel?: string;
}

export const BalanceCard = ({ topUpLabel, payoutLabel }: BalanceCardProps) => {
    const [activityToFilter, setActivityToFilter] = React.useState<
        ActivityType | ""
    >("");

    const handleTabChange = (_: any, newActivityName: ActivityType | "") => {
        setActivityToFilter(newActivityName);
    };

    return (
        <Card
            sx={{
                padding: 7,
            }}
        >
            <CardContent>
                <Stack
                    direction="row"
                    spacing={2}
                    alignItems="center"
                    marginBottom={4.5}
                >
                    <Typography
                        variant="h2"
                        sx={{
                            fontSize: "1.5rem",
                            fontWeight: "600",
                            lineHeight: "1.5rem",
                        }}
                    >
                        Balance
                    </Typography>
                    <Tabs
                        value={activityToFilter}
                        onChange={handleTabChange}
                        textColor="inherit"
                    >
                        <Tab value="" label="All" />
                        <Tab
                            value={ACTIVITY_TOP_UP}
                            label={topUpLabel || "Top Ups"}
                        />
                        <Tab
                            value={ACTIVITY_PAYOUT}
                            label={payoutLabel || "Payouts"}
                        />
                    </Tabs>
                </Stack>
                <BalanceList activityToFilter={activityToFilter} />
            </CardContent>
        </Card>
    );
};
