import LoginIcon from '@mui/icons-material/Login';
import { Box, Grid } from '@mui/material';
import { useState } from 'react';
import {
  email,
  Form,
  Login,
  PasswordInput,
  SaveButton,
  TextInput,
  useLogin,
  useNotify,
} from 'react-admin';
import logo from '../../layout/images/adzz-logo.png';

const LoginForm = () => {
  const [emailInput, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const login = useLogin();
  const notify = useNotify();

  const handleSubmit = async (e: any) => {
    try {
      await login({
        email: emailInput,
        password,
      });
    } catch (error) {
      notify((error as Error).message ?? 'An error occurred', {
        type: 'error',
        autoHideDuration: 4000,
      });
    }
  };

  return (
    <Login>
      <Box
        display="flex"
        alignItems="center"
        flexDirection="column"
        paddingBottom="30px"
      >
        <img src={logo} alt="logo" style={{ maxWidth: 150 }} />

        <Form onSubmit={handleSubmit}>
          <Grid container flexDirection="column" sx={{ padding: '10px' }}>
            <Grid item container flexDirection="column">
              <TextInput
                onChange={(e) => setEmail(e.target.value)}
                source="email"
                label="Email"
                value={emailInput}
                required
                autoComplete="on"
                validate={email()}
              />
              <PasswordInput
                source="password"
                label="Password"
                value={password}
                required
                autoComplete="off"
                onChange={(e) => setPassword(e.target.value)}
              />
            </Grid>
            <SaveButton label="Login" icon={<LoginIcon />} />
          </Grid>
        </Form>
      </Box>
    </Login>
  );
};
export default LoginForm;
