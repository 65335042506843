import { Box, Divider, Typography } from '@mui/material';
import NicheCBDIcon from '../../layout/icons/NicheCBDIcon';
import { BooleanInput, SelectInput } from 'react-admin';
import { ReferenceOneInput } from '@react-admin/ra-relationships';
import NicheBitcoinIcon from '../../layout/icons/NicheBitcoinIcon';
import NicheAdultIcon from '../../layout/icons/NicheAdultIcon';
import NicheCasinoIcon from '../../layout/icons/NicheCasinoIcon';
import NicheFinanceIcon from '../../layout/icons/NicheFinanceIcon';
import WrapperLookAlikeMUI from './WrapperLookAlikeMUI';

const CHOICES = [
    { value: 1.5, label: 'x1.5' },
    { value: 2, label: 'x2' },
    { value: 2.5, label: 'x2.5' },
    { value: 3, label: 'x3' },
    { value: 3.5, label: 'x3.5' },
    { value: 4, label: 'x4' },
    { value: 4.5, label: 'x4.5' },
    { value: 5, label: 'x5' },
    { value: 5.5, label: 'x5.5' },
    { value: 6, label: 'x6' },
    { value: 6.5, label: 'x6.5' },
    { value: 7, label: 'x7' },
    { value: 7.5, label: 'x7.5' },
    { value: 8, label: 'x8' },
    { value: 8.5, label: 'x8.5' },
    { value: 9, label: 'x9' },
    { value: 9.5, label: 'x9.5' },
    { value: 10, label: 'x10' },
];

const OfferNicheInput = () => {
    return (
        <Box sx={{ borderRadius: 1, position: 'relative' }}>
            <WrapperLookAlikeMUI label="Niche" />
            <ReferenceOneInput
                target="id"
                reference="v1_MarketPlaceOfferNiche"
                sx={{
                    padding: 3,
                    width: '100%',
                    '& .MuiFormControlLabel-root': {
                        margin: 0,
                    },
                }}
                direction={{ xs: 'column', lg: 'row' }}
                spacing={2}
                alignItems={{ xs: 'flex-start', lg: 'row' }}
            >
                <BooleanInput
                    source="isCBDAccepted"
                    label={
                        <Typography variant="body2">
                            <NicheCBDIcon /> CBD
                        </Typography>
                    }
                    helperText={false}
                />
                <SelectInput
                    source="CBDMultiply"
                    label="CBD Multiply"
                    helperText={false}
                    optionText="label"
                    optionValue="value"
                    choices={CHOICES}
                    fullWidth
                />
                <Divider />
                <BooleanInput
                    source="isCryptoAccepted"
                    label={
                        <Typography variant="body2">
                            <NicheBitcoinIcon /> Crypto
                        </Typography>
                    }
                    helperText={false}
                />
                <SelectInput
                    source="CryptoMultiply"
                    label="Crypto Multiply"
                    helperText={false}
                    optionText="label"
                    optionValue="value"
                    choices={CHOICES}
                    fullWidth
                />
                <Divider />
                <BooleanInput
                    source="isAdultAccepted"
                    label={
                        <Typography variant="body2">
                            <NicheAdultIcon /> Adult
                        </Typography>
                    }
                    helperText={false}
                />
                <SelectInput
                    source="AdultMultiplier"
                    label="Adult Multiply"
                    helperText={false}
                    optionText="label"
                    optionValue="value"
                    choices={CHOICES}
                    fullWidth
                />
                <Divider />
                <BooleanInput
                    source="isCasinoAccepted"
                    label={
                        <Typography variant="body2">
                            <NicheCasinoIcon /> Gamble
                        </Typography>
                    }
                    helperText={false}
                />
                <SelectInput
                    source="CasinoMultiply"
                    label="Gamble Multiply"
                    helperText={false}
                    optionText="label"
                    optionValue="value"
                    choices={CHOICES}
                    fullWidth
                />
                <Divider />
                <BooleanInput
                    source="isFinanceAccepted"
                    label={
                        <Typography variant="body2">
                            <NicheFinanceIcon /> Finance
                        </Typography>
                    }
                    helperText={false}
                />
                <SelectInput
                    source="FinanceMultiply"
                    label="Finance Multiply"
                    helperText={false}
                    optionText="label"
                    optionValue="value"
                    choices={CHOICES}
                    fullWidth
                />
            </ReferenceOneInput>
        </Box>
    );
};

export default OfferNicheInput;
