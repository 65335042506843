import { ChipProps } from '@mui/material';
import { useRecordContext } from 'react-admin';
import { StatusChip } from '../common/statusChip';
import { Offer, OfferStatus } from './types';

export const mapStatus = (status: number): OfferStatus => {
  switch (status) {
    case 1:
      return 'live';
    case 2:
      return 'not_finished';
    case 3:
      return 'not_posted';
    default:
      return 'live';
  }
};

const getChipProps = (
  status: OfferStatus
): Partial<Omit<ChipProps, 'label'>> => {
  switch (status) {
    case 'live':
      return { variant: 'outlined', color: 'secondary' };
    case 'not_finished':
      return { variant: 'outlined', color: 'default' };
    case 'not_posted':
      return { variant: 'outlined', color: 'warning' };
    default:
      return {};
  }
};

export const OfferStatusField = (_: OfferStatusFieldProps) => {
  const offer = useRecordContext<Offer>();
  const status = mapStatus(offer?.Status);
  if (!status) return null;
  return <StatusChip {...getChipProps(status)} status={status} />;
};

export interface OfferStatusFieldProps {
  label?: string;
  sortBy?: string;
}
