import { Typography, Card, CardContent } from "@mui/material";
import { AssistantCard } from "../assistant/assistantCard";

export const OnlineAssistant = () => {
    return (
        <Card>
            <CardContent>
                <Typography
                    variant="h3"
                    sx={{
                        marginBottom: 3.5,
                    }}
                >
                    Online Assistant
                </Typography>
                <Typography
                    sx={{
                        marginTop: 0,
                        marginBottom: 7,
                    }}
                >
                    If you have any questions or need help with your next
                    strategy, feel free to contact me by email.
                </Typography>
                <AssistantCard />
            </CardContent>
        </Card>
    );
};
