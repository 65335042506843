import { Box, Stack, Typography } from "@mui/material";
import {
  DateField,
  ReferenceField,
  TextField,
  useRecordContext
} from "react-admin";
import { MyOrders } from "./myOrders";
import { OnlineAssistant } from "./onlineAssistant";
import { PostListCard } from "../post/PostListCard";
import { ORDER_STATUS_VALUE } from "../../config/statuses";
import { PostStatusField } from "../post/PostStatusField";
import LinkInNewWindowField from "../common/linkInNewWindowField";
import { TextFieldWithTooltip } from "../common/TextFieldWithTooltip";

const PostListFields = [
  <ReferenceField
    label="Order Status"
    source="new_ContentOrderStatus"
    reference="v1_PostsContentStatus"
    link={false}
    key="new_ContentOrderStatus"
  >
    <PostStatusField />
  </ReferenceField>,
  <ReferenceField source="id" reference="v1_Posts" link="edit" label="Order ID" key={6}>
    <TextField source="id" />
  </ReferenceField>,
  <DateField
    source="ordered_on"
    label="Ordered On"
    locales="en-GB"
    key="ordered_on"
  />,
  <DateField
    source="new_Contentduedate"
    label="Due Date"
    locales="en-GB"
    key="new_Contentduedate"
  />,
  <ReferenceField
    source="website_id"
    reference="v1_Website"
    label="Publisher Site"
    link={false}
    key="website_id"
  >
    <LinkInNewWindowField
      propertyName="new_name"
      label="Publisher Site"
      showPropertyName={true}
    />
  </ReferenceField>,
  <TextFieldWithTooltip source="new_Title" label="H1 Title" key="new_Title" />,
];

export const WriterDashboard = () => {
  const { currentUser } = useRecordContext();
  
  return (
      <Stack pb={8}>
        <Typography variant="h1" mb={7}>
          Hi, {currentUser.data.FullName}
        </Typography>
        <Box display="grid" gridTemplateColumns="repeat(18, 1fr)" gap={4}>
          <Box gridColumn="span 13" gridRow="span 3">
            <PostListCard
              title="Orders"
              filterForRole={{
                writer_id: currentUser.data.id,
              }}
              statusToFilter={[
                ORDER_STATUS_VALUE.ORDERED,
                ORDER_STATUS_VALUE.DECLINED,
              ]}
              redirectToListButton
              sort={{ field: "id", order: "DESC" }}
              displayOnDashboard
            >
              {PostListFields}
            </PostListCard>
          </Box>
          <Box gridColumn="span 5">
            <MyOrders />
          </Box>
          <Box gridColumn="span 5">
            <OnlineAssistant />
          </Box>
        </Box>
      </Stack>
  );
};
