import {
    Step,
    StepConnector,
    StepIconProps,
    StepLabel,
    Stepper,
    SvgIcon,
    Typography,
    stepConnectorClasses,
    styled,
} from '@mui/material';
import { FunctionField, ReferenceField } from 'react-admin';
import StatusOrderedIcon from '../../layout/icons/StatusOrderedIcon';
import StatusWaitingApprovalIcon from '../../layout/icons/StatusWaitingApprovalIcon';
import StatusApprovedIcon from '../../layout/icons/StatusApprovedIcon';
import { ORDER_STATUS_VALUE } from '../../config/statuses';
import StatusDeclinedIcon from '../../layout/icons/StatusDeclinedIcon';

const STEPS = [
    ORDER_STATUS_VALUE.ORDERED,
    ORDER_STATUS_VALUE.WAITING_APPROVAL,
    ORDER_STATUS_VALUE.APPROVED,
];

const StatusStepperConnector = styled(StepConnector)(({ theme }) => ({
    minWidth: 20,
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor: theme.palette.primary.main,
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor: theme.palette.primary.main,
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: '6px',
        border: 0,
        backgroundColor: theme.palette.grey[400],
        borderRadius: 1,
    },
}));

const StatusStepIconRoot = styled('div')<{
    ownerState: { completed?: boolean; active?: boolean; error?: boolean };
}>(({ theme, ownerState }) => ({
    zIndex: 1,
    width: 40,
    height: 40,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.background.paper,
    border: `2px solid ${theme.palette.grey[400]}`,
    color: theme.palette.grey[400],
    ...(ownerState.active && {
        backgroundColor: theme.palette.primary.main,
        border: `2px solid ${theme.palette.background.paper}`,
        color: 'white',
    }),
    ...(ownerState.completed && {
        backgroundColor: theme.palette.background.paper,
        border: `2px solid ${theme.palette.primary.main}`,
        color: theme.palette.primary.main,
    }),
    ...(ownerState.error && {
        backgroundColor: theme.palette.warning.main,
        border: `2px solid ${theme.palette.background.paper}`,
        color: theme.palette.primary.main,
    }),
}));

function StatusStepIcon(props: StepIconProps) {
    const { active, completed, error, className } = props;
    const errorIcon = (
        <SvgIcon
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: 20,
                height: 21,
            }}
        >
            <StatusDeclinedIcon />
        </SvgIcon>
    );

    const icons: { [index: string]: React.ReactElement } = {
        1: (
            <SvgIcon
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: 20,
                    height: 21,
                }}
            >
                <StatusOrderedIcon />
            </SvgIcon>
        ),
        2: (
            <SvgIcon
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: 20,
                    height: 21,
                }}
            >
                <StatusWaitingApprovalIcon />
            </SvgIcon>
        ),
        3: (
            <SvgIcon
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: 20,
                    height: 21,
                }}
            >
                <StatusApprovedIcon />
            </SvgIcon>
        ),
    };

    return (
        <StatusStepIconRoot
            ownerState={{ completed, active, error }}
            className={className}
        >
            {error ? errorIcon : icons[String(props.icon)]}
        </StatusStepIconRoot>
    );
}

function isStatusDeclined(value: any) {
    return value === ORDER_STATUS_VALUE.DECLINED;
}

function getActiveStep(value: any) {
    return isStatusDeclined(value)
        ? 1
        : STEPS.findIndex((step) => step === value);
}

const StatusStepper = ({ value }: any) => {
    const indexActiveStep = getActiveStep(value);
    return (
        <Stepper
            activeStep={indexActiveStep}
            connector={<StatusStepperConnector />}
        >
            {STEPS.map((label, index) => {
                if (value === 'Declined' && index === 1) {
                    return (
                        <Step key={label}>
                            <StepLabel
                                StepIconComponent={StatusStepIcon}
                                error={true}
                                sx={{
                                    '& .MuiStepLabel-iconContainer': {
                                        padding: 0,
                                    },
                                    '& .MuiStepLabel-label': {
                                        paddingX: 1,
                                    },
                                }}
                            >
                                Content Declined
                            </StepLabel>
                        </Step>
                    );
                }

                return (
                    <Step key={label}>
                        <StepLabel
                            StepIconComponent={StatusStepIcon}
                            sx={{
                                '& .MuiStepLabel-iconContainer': {
                                    padding: 0,
                                },
                                '& .MuiStepLabel-label': {
                                    paddingX: 1,
                                },
                            }}
                        >
                            {index === indexActiveStep && (
                                <Typography color="primary" fontWeight={600}>
                                    {label}
                                </Typography>
                            )}
                        </StepLabel>
                    </Step>
                );
            })}
        </Stepper>
    );
};

const OrderEditStatus = () => {
    return (
        <ReferenceField
            label="Order Status"
            source="new_ContentOrderStatus"
            reference="v1_PostsContentStatus"
        >
            <FunctionField
                render={(record: any) => <StatusStepper value={record.value} />}
            />
        </ReferenceField>
    );
};
export default OrderEditStatus;
