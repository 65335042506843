import { EventRecord } from "@react-admin/ra-audit-log";
import { useCallback } from "react";
import {
    RaRecord,
    useCreate,
    UseCreateMutateParams,
    useGetIdentity,
    useRecordContext,
    useResourceContext,
} from "react-admin";
import { MutateOptions, useQueryClient } from "react-query";

export const useAddRecordEvent = <
    TPayload = any,
    TReturnPromise extends boolean = boolean,
    MutationError = unknown
>(
    options: UseAddRecordEventOptions<TReturnPromise, MutationError> = {}
): UseAddRecordEventHookValue<TPayload, TReturnPromise> => {
    const queryClient = useQueryClient();
    const record = useRecordContext(options);
    const resource = useResourceContext(options);
    const { identity } = useGetIdentity();
    const { eventResource = "v1_Events", mutationOptions } = options;
    const [create] = useCreate<EventRecord, MutationError>();

    const addRecordEvent: UseAddRecordEventHookValue = useCallback(
        (action, data) => {
            return create(
                eventResource,
                {
                    data: {
                        date: new Date(),
                        author: identity,
                        resource,
                        action,
                        payload: {
                            data: { id: record?.id },
                            ...data,
                        },
                    },
                },
                {
                    onSuccess: () => {
                        queryClient.invalidateQueries([eventResource]);
                    },
                    ...mutationOptions,
                }
            );
        },
        [
            create,
            eventResource,
            identity,
            mutationOptions,
            queryClient,
            record?.id,
            resource,
        ]
    );

    return addRecordEvent;
};

export interface UseAddRecordEventOptions<
    TReturnPromise extends boolean = boolean,
    MutationError = unknown
> {
    record?: RaRecord;
    resource?: string;
    eventResource?: string;

    mutationOptions?: MutateOptions<
        EventRecord,
        MutationError,
        Partial<UseCreateMutateParams>,
        unknown
    > & { returnPromise?: TReturnPromise };
}
export type UseAddRecordEventHookValue<
    TPayload = any,
    TReturnPromise extends boolean = boolean
> = (
    action: string,
    data?: TPayload
) => Promise<TReturnPromise extends true ? EventRecord : void>;
