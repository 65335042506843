import { Typography, Stack, Card, CardContent } from '@mui/material';
import {useGetIdentity} from "../../hooks/useGetIdentity";
import {Loading, useGetOne} from "react-admin";
import React from "react";

export const MyOffers = () => {
  const {data: identity}=useGetIdentity();
  const supplierId=identity?.id;
  const { data: supplier, isLoading: supplierLoading } = useGetOne(
      'v1_ContactBase',
      {
        id: supplierId,
      },
      { enabled: !!supplierId }
  );
  if (supplierLoading) return <Loading />;
  const amountFormatter = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  });
  return (
    <Card>
      <CardContent>
        <Stack>
          <Typography variant="h3">My Offers</Typography>
          <Stack direction="row" spacing={6}>
            <Stack>
              <Typography color="text.secondary">Total</Typography>
              <Typography variant="h1">
                {amountFormatter.format(supplier.TotalOffersCount)}
              </Typography>
            </Stack>
            <Stack>
              <Typography color="text.secondary">Sold</Typography>
              <Typography variant="h1" color="secondary.main">
                {amountFormatter.format(supplier.SoldOffersCount)}
              </Typography>
            </Stack>
            <Stack>
              <Typography color="text.secondary">Active</Typography>
              <Typography variant="h1" color="primary">
                {amountFormatter.format(supplier.ActiveOffersCount)}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};
