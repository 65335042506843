import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { nhost } from "../lib/nhost";

let nhostToken: string;

nhost.auth.onTokenChanged(() => {
  nhostToken = nhost.auth.getAccessToken() as string;
});

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_HASURA_PROXY,
});

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      authorization: nhostToken ? `Bearer ${nhostToken}` : "",
    },
  };
});

export const clientOptions: any = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});
