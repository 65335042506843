import Box from '@mui/material/Box';
import {
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Switch,
} from '@mui/material';
import { useInput } from 'react-admin';

interface BooleanFilterProps {
    source: string;
    label: string;
}

export default function BooleanFilter({
    source,
    label,
}: BooleanFilterProps) {
    const { field } = useInput({
        source: `${source}@_eq`,
        defaultValue: false,
    });

    const handleChange = (event: any) => {
        field.onChange(event.target.checked);
    };

    return (
        <Box sx={{ width: 370 }}>
            <FormControl component="fieldset" variant="standard">
                <FormLabel component="legend">{label}</FormLabel>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={field.value}
                                onChange={handleChange}
                            />
                        }
                        label="YES"
                    />
                </FormGroup>
            </FormControl>
        </Box>
    );
}
