import { isValidElement } from "react";
import { Stack, Typography } from "@mui/material";
import EastIcon from "@mui/icons-material/East";
import { EventRecord, useEventLabel } from "@react-admin/ra-audit-log";
import { RecordContextProvider, useTranslate } from "react-admin";
import { getAuditedFieldDisplayComponent as getAuditedFieldDisplayComponentPosts } from "../post/getAuditedFieldDisplayComponent";
import { PostContentStatusField } from "../post/postContentStatusField";

const getUpdatedField = (payload: EventRecord["payload"]) => {
  if (payload?.previousData) {
    for (const field of Object.keys(payload.previousData)) {
      if (payload.data && payload.data[field] !== payload.previousData[field]) {
        return field;
      }
    }
  }
  return null;
};

const getAuditedFieldDisplayComponent = (
  resource: string,
  updatedField: string | null
) => {
  if (!updatedField) return null;
  switch (resource) {
    case "v1_Posts":
      return getAuditedFieldDisplayComponentPosts(updatedField);
  }
  return null;
};

const PreviousAndNewValue = ({
  DisplayComponent,
  payload,
  updatedField,
}: {
  DisplayComponent: React.FC | null;
  payload: EventRecord["payload"];
  updatedField: string | null;
}) => {
  if (!DisplayComponent || !updatedField) return null;
  const hasBothPreviousAndNewValue =
    payload?.previousData &&
    payload?.data &&
    payload.previousData[updatedField] &&
    payload.data[updatedField];

  return (
    <Stack direction="row" alignItems="flex-start" spacing={1}>
      {hasBothPreviousAndNewValue ? (
        <RecordContextProvider value={payload.previousData}>
          {isValidElement(DisplayComponent) ? (
            DisplayComponent
          ) : (
            <DisplayComponent />
          )}
        </RecordContextProvider>
      ) : null}
      {payload?.data[updatedField] ? (
        <>
          <Typography color="textPrimary" variant="subtitle2">
            <EastIcon fontSize="small" />
          </Typography>
          <RecordContextProvider value={payload.data}>
            {isValidElement(DisplayComponent) ? (
              DisplayComponent
            ) : (
              <DisplayComponent />
            )}
          </RecordContextProvider>
        </>
      ) : null}
    </Stack>
  );
};

export const EventLabel = ({ record }: { record: EventRecord }) => {
  let defaultLabel = useEventLabel({ record, variant: "record" });
  const t = useTranslate();
  const resource = record.resource;
  const commentToWriter = record?.payload?.new_CommentToWriter;
  switch (record.action) {
    case "update":
      const updatedField = getUpdatedField(record.payload);
      const DisplayComponent = getAuditedFieldDisplayComponent(
        resource,
        updatedField
      );
      return (
        <>
          <Stack direction="row">
            <Typography color="textPrimary" variant="subtitle2">
              {t("adzz.timeline.update")}&nbsp;
            </Typography>
            <Typography color="primary" variant="subtitle2">
              {t(`adzz.resources.${resource}.fields.${updatedField}`, {
                _: updatedField,
              })}
            </Typography>
          </Stack>
          <PreviousAndNewValue
            DisplayComponent={DisplayComponent}
            payload={record?.payload}
            updatedField={updatedField}
          />
        </>
      );

    case "Decline":
      return (
        <Stack direction="row">
          <Typography color="textPrimary" variant="subtitle2">
            {commentToWriter ? (
              <>
                {t("adzz.timeline.Decline.with_comment")}
                &nbsp;
              </>
            ) : (
              <>{t("adzz.timeline.Decline.without_comment")}</>
            )}
          </Typography>
          {commentToWriter ? (
            <Typography color="error" variant="subtitle2">
              {commentToWriter}
            </Typography>
          ) : null}
        </Stack>
      );

    case "Approve":
      return (
        <Stack direction="row">
          <Typography color="textPrimary" variant="subtitle2">
            {commentToWriter ? (
              <>{t("adzz.timeline.Approve.with_comment")}</>
            ) : (
              <>{t("adzz.timeline.Approve.without_comment")}</>
            )}
          </Typography>
          {commentToWriter ? (
            <Typography color="primary" variant="subtitle2">
              {commentToWriter}
            </Typography>
          ) : null}
        </Stack>
      );

    case "copyscape":
      const copyScapeResponse = record?.payload?.copyScapeResponse ?? {};
      const { error, allpercentmatched } = copyScapeResponse;
      return error ? (
        <Typography color="error" variant="subtitle2">
          {t("adzz.timeline.copyscape.error", {error})}
        </Typography>
      ) : (
        <Typography color="primary" variant="subtitle2">
          {t("adzz.timeline.copyscape.success", {allpercentmatched})}
        </Typography>
      );

    case "PostLinkCheck":
      return (
        <Typography color="textPrimary" variant="subtitle2">
          {t("adzz.timeline.PostLinkCheck")}
        </Typography>
      );

    case "PostChangeStatus":
      return (
        <>
          <Stack direction="row">
            <Typography color="textPrimary" variant="subtitle2">
              {t("adzz.timeline.PostChangeStatus")}&nbsp;
            </Typography>
            <Typography color="primary" variant="subtitle2">
              {t(
                `adzz.resources.${resource}.fields.new_ContentOrderStatus`,
                {
                  _: "Content Status",
                }
              )}
            </Typography>
          </Stack>
          <PreviousAndNewValue
            DisplayComponent={PostContentStatusField}
            payload={record?.payload}
            updatedField="new_ContentOrderStatus"
          />
        </>
      );

    default:
      return (
        <Typography color="textPrimary" variant="subtitle2">
          {defaultLabel}
        </Typography>
      );
  }
};
