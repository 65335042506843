import { Button, Stack, TextField, Typography } from '@mui/material';
import CreateIcon from '@mui/icons-material/Create';
import React, { useState } from 'react';
import { useRecordContext, useResourceContext } from 'react-admin';
import { useQueryClient } from 'react-query';
import { useAddComment } from '../../hooks/useAddComment';

export const CommentForm = ({
  eventResource = 'v1_Events',
  notifyWriter,
  notifyPublisher,
  visibilityForm,
}: {
  eventResource?: string;
  notifyWriter?: boolean;
  notifyPublisher?: boolean;
  visibilityForm?: React.ReactNode;
}) => {
  const record = useRecordContext();
  const resource = useResourceContext();
  const queryClient = useQueryClient();

  const addComment = useAddComment({
    record,
    resource,
    mutationOptions: {
      onSuccess: () => {
        queryClient.invalidateQueries([eventResource, 'getList']);
      },
      returnPromise: true,
    },
  });

  const [commentBody, setCommentBody] = useState('');

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    addComment({ body: commentBody, notifyWriter, notifyPublisher }).then(
      () => {
        setCommentBody('');
      }
    );
    return false;
  };

  return (
    <form onSubmit={handleSubmit}>
      <Stack
        sx={(theme) => ({
          padding: 2,
          backgroundColor: theme.palette.grey[100],
        })}
      >
        <TextField
          value={commentBody}
          onChange={(event) => setCommentBody(event.target.value)}
          multiline
          rows={1}
          placeholder="Write a comment..."
          variant="standard"
          InputProps={{
            disableUnderline: true,
          }}
          sx={{ padding: 1 }}
        />
        <Stack direction="row" spacing={2} alignItems="center" mt={2}>
          <Button
            type="submit"
            variant="contained"
            disabled={!commentBody}
            fullWidth
          >
            <Stack direction="row" spacing={2} alignItems="center">
              <CreateIcon />
              {visibilityForm ? (
                <Typography variant="subtitle1">Send to</Typography>
              ) : (
                <Typography variant="subtitle1">Send</Typography>
              )}
            </Stack>
          </Button>
          {visibilityForm && visibilityForm}
        </Stack>
      </Stack>
    </form>
  );
};
