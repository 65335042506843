import { Typography } from "@mui/material";
import { useRecordContext } from "react-admin";
import { ACTIVITY_PAYOUT, ACTIVITY_TOP_UP, ActivityType } from "./types";

const getColor = (activity: ActivityType) => {
    switch (activity) {
        case ACTIVITY_TOP_UP:
            return "secondary.main";
        case ACTIVITY_PAYOUT:
            return "primary";
        default:
            return undefined;
    }
};

export const ActivityField = (_: ActivityFieldProps) => {
    const activity = useRecordContext();

    if (!activity) return null;
    return (
        <Typography color={getColor(activity.Name)} sx={{ fontWeight: 600 }}>
            {activity.Name}
        </Typography>
    );
};

export interface ActivityFieldProps {
    label?: string;
}
