import LanguageFilter from './LanguageFilter';
import RangeSlider from './RangeSlider';

import ahrefsLogo from '../../../layout/images/ahrefs-logo.png';
import MozLogo from '../../../layout/images/moz-logo.png';
import SemrushLogo from '../../../layout/images/semrush-logo.png';
import DateFilter from './DateFilter';
import BooleanFilter from './BooleanFilter';
import DomainExtensionFilter from './DomainExtensionFilter';
import CategoryFilter from './CategoryFilter';

export interface FilterProps {
    name: string;
    label: string;
    source: string;
    component: any;
    display: boolean;
    icon?: any;
}

export const FILTERS = [
    {
        name: 'LanguageFilter',
        label: 'Language',
        source: 'LanguageId',
        component: LanguageFilter,
        display: true,
    },
    {
        name: 'PriceFilter',
        label: 'Price',
        source: 'ExternalPrice',
        component: RangeSlider,
        display: true,
        range: {
            min: 0,
            max: 15000,
        },
    },
    {
        name: 'AhrefsDRFilter',
        label: 'Ahrefs DR',
        source: 'Website#AhrefsDR',
        component: RangeSlider,
        display: true,
        icon: ahrefsLogo,
        range: {
            min: 0,
            max: 100,
        },
    },
    {
        name: 'SemrushFilter',
        label: 'Authority Score',
        source: 'Website#SemrushAS',
        component: RangeSlider,
        display: true,
        icon: SemrushLogo,
        range: {
            min: 0,
            max: 100,
        },
    },
    {
        name: 'MOZDaFilter',
        label: 'Domain Authority',
        component: RangeSlider,
        source: 'Website#MOZDa',
        display: true,
        icon: MozLogo,
        range: {
            min: 0,
            max: 100,
        },
    },
    {
        name: 'MOZSpamScoreFilter',
        label: 'Spam Score',
        component: RangeSlider,
        source: 'Website#MOZSpamScore',
        display: true,
        icon: MozLogo,
        range: {
            min: 0,
            max: 100,
        },
    },
    // This filter not working
    // { 
    //     name: 'OrganicTrafficFilter',
    //     label: 'Organic Traffic',
    //     source: 'Website#OrganicTraffic',
    //     component: RangeSlider,
    //     display: true,
    //     range: {
    //         min: 0,
    //         max: 100000,
    //     },
    // },
    {
        name: 'ReferringDomainsFilter',
        label: 'Referring Domains',
        source: 'Website#ReferringDomains',
        component: RangeSlider,
        display: true,
        range: {
            min: 0,
            max: 100000,
        },
    },
    {
        name: 'DomainAge',
        label: 'Domain Age',
        source: 'Website#DomainAge',
        component: DateFilter,
        display: false,
    },
    {
        name: 'OutgoingDomains',
        label: 'Outgoing Domains',
        source: 'Website#OutgoingDomains',
        component: RangeSlider,
        display: false,
        range: {
            min: 0,
            max: 100000,
        },
    },
    {
        name: 'Sichtbarkeitsindex',
        label: 'SISTRIX Visibility Index',
        source: 'Website#Sichtbarkeitsindex',
        component: RangeSlider,
        display: false,
        range: {
            min: 0,
            max: 100,
        },
    },
    {
        name: 'UploadDate',
        label: 'Upload Age',
        source: 'AddedOn',
        component: DateFilter,
        display: false,
    },
    {
        name: 'MarketPlaceCategorie',
        label: 'Category',
        source: 'MarketPlaceCategoriesId',
        component: CategoryFilter,
        display: false,
    },
    {
        name: 'HomepageLink',
        label: 'Homepage Link',
        source: 'IsHomepageLink',
        component: BooleanFilter,
        display: false,
    },
    {
        name: 'NativeLink',
        label: 'Native Link',
        source: 'Website#NativeLink',
        component: BooleanFilter,
        display: false,
    },
    {
        name: 'DomainExtensions',
        label: 'Domain Extensions',
        source: 'DomainExtensionId',
        component: DomainExtensionFilter,
        display: false,
    },
];
