import { EditBase, EditView } from 'react-admin';
import RecordTimeLineWrapper from './../common/recordTimelineWrapper';
import OrderForm from './orderForm';
import OrderEditTitle from './OrderEditTitle';
import OrderEditStatus from './OrderEditStatus';
import { Box } from '@mui/material';

const OrderEdit = () => {
    return (
        <EditBase redirect={false}>
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="self-start"
                sx={{ flexDirection: { xs: 'column', md: 'row' } }}
            >
                <OrderEditTitle />
                <OrderEditStatus />
            </Box>
            <EditView aside={<RecordTimeLineWrapper />}>
                <OrderForm />
            </EditView>
        </EditBase>
    );
};
export default OrderEdit;
