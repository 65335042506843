import gql from "graphql-tag";
import { CONTENT_STATUS } from "../config/statuses";

export const GET_WRITER = gql`
  {
    id
    FullName
    owner_id
    approved: Posts_aggregate(
      where: { new_ContentOrderStatus: { _eq: ${CONTENT_STATUS.APPROVED} } }
    ) {
      aggregate {
        count
      }
    }
    waiting: Posts_aggregate(
      where: { new_ContentOrderStatus: { _eq: ${CONTENT_STATUS.WAITING_APPROVAL} } }
    ) {
      aggregate {
        count
      }
    }
    declined: Posts_aggregate(
      where: { new_ContentOrderStatus: { _eq: ${CONTENT_STATUS.DECLINED} } }
    ) {
      aggregate {
        count
      }
    }
    ordered: Posts_aggregate(
      where: { new_ContentOrderStatus: { _eq: ${CONTENT_STATUS.ORDERED} } }
    ) {
      aggregate {
        count
      }
    }
  }
`;
