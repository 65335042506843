const NicheAdultIcon = () => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M19.9059 0.729228C19.7866 0.44183 19.5582 0.213446 19.2708 0.0940938C19.1294 0.0338255 18.9775 0.00185834 18.8238 0H14.1191C13.8072 0 13.508 0.123918 13.2875 0.344493C13.0669 0.565069 12.943 0.864233 12.943 1.17617C12.943 1.48811 13.0669 1.78728 13.2875 2.00785C13.508 2.22843 13.8072 2.35235 14.1191 2.35235H15.9892L13.1664 5.17517C11.4997 3.92777 9.42229 3.35809 7.35241 3.58081C5.28253 3.80353 3.37387 4.80211 2.01065 6.37553C0.64744 7.94895 -0.0691072 9.98038 0.00525939 12.0609C0.079626 14.1414 0.939384 16.1165 2.41146 17.5885C3.88353 19.0606 5.85862 19.9204 7.93912 19.9947C10.0196 20.0691 12.051 19.3526 13.6245 17.9893C15.1979 16.6261 16.1965 14.7175 16.4192 12.6476C16.6419 10.5777 16.0722 8.50031 14.8248 6.83357L17.6477 4.01075V5.88087C17.6477 6.19281 17.7716 6.49198 17.9921 6.71255C18.2127 6.93313 18.5119 7.05704 18.8238 7.05704C19.1358 7.05704 19.4349 6.93313 19.6555 6.71255C19.8761 6.49198 20 6.19281 20 5.88087V1.17617C19.9981 1.02247 19.9662 0.87063 19.9059 0.729228V0.729228ZM12.3549 15.8783C11.5372 16.7126 10.4896 17.2844 9.34562 17.5208C8.20167 17.7573 7.01321 17.6478 5.93177 17.2061C4.85034 16.7645 3.92492 16.0108 3.27351 15.0412C2.6221 14.0715 2.27422 12.9299 2.27422 11.7617C2.27422 10.5936 2.6221 9.45193 3.27351 8.48229C3.92492 7.51265 4.85034 6.75896 5.93177 6.31734C7.01321 5.87572 8.20167 5.76616 9.34562 6.00264C10.4896 6.23912 11.5372 6.81092 12.3549 7.64513V7.64513C13.4324 8.74444 14.036 10.2224 14.036 11.7617C14.036 13.3011 13.4324 14.779 12.3549 15.8783V15.8783Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default NicheAdultIcon;
