import Link from '@mui/material/Link/Link';
import { FunctionField } from 'react-admin';
import LaunchIcon from '@mui/icons-material/Launch';

type LinkInNewWindowFieldProps = {
    label: string;
    propertyName: string;
    showPropertyName?: boolean;
    source?: string;
};
const LinkInNewWindowField = (props: LinkInNewWindowFieldProps) => {
    const { label, propertyName, showPropertyName } = props;
    return (
        <FunctionField label={label} render={(record?: any) => {
            if (!record || !record[propertyName]) return null;

            const linkUrl = record[propertyName].startsWith('http') ?
                record[propertyName] :
                `https://${record[propertyName]}`;

            return (
                <Link rel="noreferrer noopener" target="_blank" href={linkUrl}>
                    {showPropertyName && record[propertyName]}
                    <LaunchIcon />
                </Link>
            );
        }} />
    );
};
export default LinkInNewWindowField;
