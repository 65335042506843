import { List } from "react-admin";
import { BalanceDatagrid } from "./balanceDatagrid";
import { useGetIdentity } from "../../hooks/useGetIdentity";
import { Box, Typography } from "@mui/material";
import { ActivityType } from "./types";

export interface BalanceListProps {
    activityToFilter: ActivityType | "";
}

interface EmptyProps {
    activity: ActivityType | "";
}

const Empty = ({ activity }: EmptyProps) => {
    const emptyText = !!activity
        ? `No ${activity.toLowerCase()} transactions yet.`
        : "No transactions yet.";
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
            }}
        >
            <Typography
                variant="h2"
                sx={{
                    fontSize: "1.5rem",
                    lineHeight: "1.5rem",
                    color: "neutral.secondary",
                }}
            >
                {emptyText}
            </Typography>
        </Box>
    );
};

export const BalanceList = ({ activityToFilter }: BalanceListProps) => {
    const { data: identity } = useGetIdentity();
    const writerId = identity?.id;

    const activityFilter = activityToFilter
        ? {
              "BalanceActivityType#Name@_eq": activityToFilter,
          }
        : {};
    return (
        <List
            perPage={6}
            actions={false}
            sort={{ field: "Date", order: "DESC" }}
            filter={{
                UserId: writerId,
                ...activityFilter,
            }}
            pagination={false}
            resource="v1_Balance"
            empty={<Empty activity={activityToFilter} />}
        >
            <BalanceDatagrid />
        </List>
    );
};
