import * as React from 'react';
import { useStore, useResourceContext } from 'ra-core';
import {
    Box,
    Button,
    Popover,
    useMediaQuery,
    Theme,
    Tooltip,
    IconButton,
    Typography,
} from '@mui/material';
import ReorderIcon from '@mui/icons-material/Reorder';
import CloseIcon from '@mui/icons-material/Close';

import { styled } from '@mui/material/styles';
import { ConfigurableDatagridColumn } from 'react-admin';
import { MarketPlaceFieldCheckbox } from './MarketPlaceFieldCheckbox';

/**
 * Renders a button that lets users show / hide columns in a configurable datagrid
 *
 * @example
 * import { SelectColumnsButton, DatagridConfigurable } from 'react-admin';
 *
 * const PostListActions = () => (
 *   <TopToolbar>
        <SelectColumnsButton />
        <FilterButton />
 *   </TopToolbar>
 * );
 *
 * const PostList = () => (
 *   <List actions={<PostListActions />}>
 *     <DatagridConfigurable>
 *       <TextField source="title" />
 *       <TextField source="author" />
         ...
 *     </DatagridConfigurable>
 *   </List>
 * );
 */

const NOT_DISPLAYED_SOURCE = ['price', 'like', 'website_name'] 

export const MarketPlaceSelectColumnsButton = () => {
    const resource = useResourceContext();
    const finalPreferenceKey = `${resource}.datagrid`;

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [availableColumns, setAvailableColumns] = useStore<
        ConfigurableDatagridColumn[]
    >(`preferences.${finalPreferenceKey}.availableColumns`, []);
    const [omit] = useStore<string[]>(
        `preferences.${finalPreferenceKey}.omit`,
        [],
    );
    const [columns, setColumns] = useStore<string[]>(
        `preferences.${finalPreferenceKey}.columns`,
        availableColumns
            .filter((column) => !omit?.includes(column.source!))
            .map((column) => column.index),
    );

    const isXSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm'),
    );

    const title = 'Columns Settings';

    const handleClick = (event: any): void => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (): void => {
        setAnchorEl(null);
    };

    const handleToggle = (event: any) => {
        if (event.target.checked) {
            // add the column at the right position
            setColumns(
                availableColumns
                    .filter(
                        (column) =>
                            column.index === event.target.name ||
                            columns.includes(column.index),
                    )
                    .map((column) => column.index),
            );
        } else {
            setColumns(columns.filter((index) => index !== event.target.name));
        }
    };

    const handleMove = (index1: string, index2: string) => {
        const index1Pos = availableColumns.findIndex(
            // eslint-disable-next-line eqeqeq
            (field) => field.index == index1,
        );
        const index2Pos = availableColumns.findIndex(
            // eslint-disable-next-line eqeqeq
            (field) => field.index == index2,
        );
        if (index1Pos === -1 || index2Pos === -1) {
            return;
        }
        let newAvailableColumns: any;
        if (index1Pos > index2Pos) {
            newAvailableColumns = [
                ...availableColumns.slice(0, index2Pos),
                availableColumns[index1Pos],
                ...availableColumns.slice(index2Pos, index1Pos),
                ...availableColumns.slice(index1Pos + 1),
            ];
        } else {
            newAvailableColumns = [
                ...availableColumns.slice(0, index1Pos),
                ...availableColumns.slice(index1Pos + 1, index2Pos + 1),
                availableColumns[index1Pos],
                ...availableColumns.slice(index2Pos + 1),
            ];
        }
        setAvailableColumns(newAvailableColumns);
        setColumns((columns) =>
            newAvailableColumns
                .filter((column: any) => columns.includes(column.index))
                .map((column: any) => column.index),
        );
    };

    return (
        <>
            {isXSmall ? (
                <Tooltip title={title}>
                    <IconButton
                        aria-label={title}
                        sx={{
                            color: 'neutral.main',
                        }}
                        onClick={handleClick}
                        size="large"
                    >
                        <ReorderIcon />
                    </IconButton>
                </Tooltip>
            ) : (
                <StyledButton
                    size="small"
                    onClick={handleClick}
                    aria-label={title}
                    sx={{
                        color: 'neutral.main',
                    }}
                    startIcon={<ReorderIcon />}
                >
                    {title}
                </StyledButton>
            )}
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            padding: 4,
                            borderBottom: `1px solid`,
                            borderColor: 'divider',
                        }}
                    >
                        <Typography
                            sx={{
                                fontWeight: 'bold',
                                fontSize: '1.1rem',
                            }}
                        >
                            Edit Criteria
                        </Typography>
                        <IconButton onClick={handleClose} sx={{ padding: 0 }}>
                            <CloseIcon sx={{ padding: 0 }} />
                        </IconButton>
                    </Box>
                    {availableColumns
                        .filter((column) => !NOT_DISPLAYED_SOURCE.includes(column.source!))
                        .map((column) => (
                            <MarketPlaceFieldCheckbox
                                key={column.index}
                                source={column.source}
                                label={column.label}
                                index={column.index}
                                selected={columns.includes(column.index)}
                                onToggle={handleToggle}
                                onMove={handleMove}
                            />
                        ))}
                </Box>
            </Popover>
        </>
    );
};

const StyledButton = styled(Button, {
    name: 'RaSelectColumnsButton',
    overridesResolver: (props, styles) => styles.root,
})({
    '&.MuiButton-sizeSmall': {
        // fix for icon misalignment on small buttons, see https://github.com/mui/material-ui/pull/30240
        lineHeight: 1.5,
        minWidth: 150,
    },
});
