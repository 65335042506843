import { IconButton } from '@mui/material';
import { useState } from 'react';
import { FunctionField } from 'react-admin';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';

const LikeField = ({ label }: { label: string, source: string }) => {
    const [like, setLike] = useState(false);

    const handleClick = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        setLike((prevLike) => !prevLike);
    };

    return (
        <FunctionField
            label={label}
            render={(record?: any) => {
                return (
                    <IconButton
                        aria-label="like"
                        onClick={handleClick}
                        color="primary"
                    >
                        {like ? <FavoriteIcon /> : <FavoriteBorderIcon />}
                    </IconButton>
                );
            }}
        />
    );
};

export default LikeField;
