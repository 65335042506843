import Box from '@mui/material/Box';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useGetList, useInput } from 'react-admin';

export default function LanguageFilter() {
    const { data: languages } = useGetList('v1_new_languagesBase', {
        pagination: { page: 1, perPage: 1000 },
    });

    const { field } = useInput({
        source: `LanguageId@_eq`,
        defaultValue: '',
    });

    const handleChange = (event: any) => {
        if (event.target.value === '') {
            field.onChange('');
        } else {
            field.onChange(event.target.value);
        }
    };

    return (
        <Box sx={{ width: 370 }}>
            <FormControl fullWidth>
                <InputLabel id="language-filter">Language</InputLabel>
                <Select
                    labelId="language-filter"
                    id="language-filter-select"
                    label="Language"
                    value={field.value}
                    onChange={handleChange}
                >
                    <MenuItem value="">All</MenuItem>
                    {languages?.map((language: any) => (
                        <MenuItem key={language.id} value={language.id}>
                            {language.new_name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );
}
