import {
    AppLocationContext,
    ContainerLayout,
} from '@react-admin/ra-navigation';
import MenuList from './MenuList';
import MyAppBar from './myAppBar';
import { UserMenu } from './userMenu';
import { useLocation } from 'react-router-dom';
import { useGetIdentity } from 'react-admin';

const MyLayout = (props: any) => {
    const location = useLocation();
    const identity = useGetIdentity();
    /* const [languageBase, setLanguageBase] = useStore('languageBase', {})
        const {data: languageBaseItems, isLoading: languageBaseLoading} = useGetList('v1_new_languagesBase') as any;
        useEffect(() => {
            if (!languageBaseLoading) {

                setLanguageBase(languageBaseItems)
            }
        }, [languageBaseLoading])*/
    return (
        <AppLocationContext>
            <ContainerLayout
                {...props}
                menu={<MenuList />}
                appBar={<MyAppBar />}
                userMenu={<UserMenu />}
                sx={{
                    '& > .RaContainerLayout-content': {
                        marginTop: 7,
                    },
                    '& > .MuiContainer-root': {
                        maxWidth:
                            identity?.data?.role === 'Advertiser' &&
                            location.pathname.includes('/v1_Offer')
                                ? '100%'
                                : 'lg',
                    },
                }}
            />
        </AppLocationContext>
    );
};

export default MyLayout;
