import {
    Datagrid,
    DatagridProps,
    DateField,
    NumberField,
    ReferenceField,
} from "react-admin";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { NullableTextField } from "../common/nullableTextField";
import { IconButton } from "@mui/material";
import { AmountField } from "../common/amountField";
import { ActivityField } from "./activityField";

export const BalanceDatagrid = (props: DatagridProps) => (
    <Datagrid bulkActionButtons={false} rowClick="edit" {...props}>
        <DateField label="Date" source="Date" locales="en-GB" />
        <ReferenceField
            source="Activity"
            label="Activity"
            reference="v1_BalanceActivityType"
        >
            <ActivityField />
        </ReferenceField>
        <NullableTextField source="OrderID" label="Order ID" />
        <ReferenceField
            source="PaymentMethod"
            label="Method"
            reference="v1_BillingMethod"
        >
            <NullableTextField source="Name" />
        </ReferenceField>
        <AmountField label="Amount" source="Amount" activitySource="Activity" />
        <NumberField
            label="Balance"
            source="Balance"
            textAlign="left"
            align="left"
            locales="en-GB"
            options={{
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 0,
                minimumFractionDigits: 0,
            }}
        />
        <IconButton color="primary">
            <DescriptionOutlinedIcon color="primary" />
        </IconButton>
    </Datagrid>
);
