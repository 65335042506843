import { Stack } from '@mui/material';
import { LoadingIndicator } from 'react-admin';

export const MyToolbar = () => {
  return (
    <Stack direction="row" spacing={2} alignItems="center">
      {/* @ts-ignore TS type doesn't include size although it is supported */}
      <LoadingIndicator size={16} />
    </Stack>
  );
};
